import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class FAQ extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Sıkça Sorulan Sorular | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Sıkça Sorulan Sorular | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Sıkça Sorulan Sorular | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Sıkça Sorulan Sorular | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="text-left">
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Sıkça Sorulan Sorular
                                    </h1>
                                    <h6 className="font-weight-bold">Nasıl kayıt olabilirim?</h6>
                                    <p>Kayıt olmak için ana sayfada bulunan "Kayıt Ol" butonuna tıklamanız yeterlidir. Adınızı, e-posta adresinizi ve şifrenizi girerek kayıt işlemini tamamlayabilirsiniz.</p>

                                    <h6 className="font-weight-bold">Bir buzdolabı edinmek zorunda mıyım?</h6>
                                    <p>Hayır, bir buzdolabı edinmek zorunda değilsiniz. Ancak, kaydolursanız kendi sanal buzdolabınızı kullanarak malzemelerinizi kolayca kaydedebilir ve tariflerinizi daha hızlı oluşturabilirsiniz.</p>

                                    <h6 className="font-weight-bold">Hangi malzemeleri seçebilirim?</h6>
                                    <p>Wasteys'te 6 kategori bulunmaktadır: Tahıllar, Süt Ürünleri, Et, Sebzeler & Meyveler & Kuruyemişler, Baharatlar & Otlar & Diğerleri. Bu kategorilerden dilediğiniz malzemeleri seçebilirsiniz.</p>

                                    <h6 className="font-weight-bold">Tarifler ne kadar hızlı bir şekilde görüntülenir?</h6>
                                    <p>Tarifler, malzemelerinizi seçtikten hemen sonra saniyeler içinde görüntülenir.</p>

                                    <h6 className="font-weight-bold">Kaç tane tarif bulabilirim?</h6>
                                    <p>Bu, seçtiğiniz malzemeler ve kategorilere bağlıdır. Ancak, Wasteys'te 17.000'den fazla tarif bulunmaktadır, bu nedenle seçtiğiniz malzemelere göre birçok seçenek bulabilirsiniz.</p>

                                    <h6 className="font-weight-bold">Tariflerin besin değerleri hakkında bilgi alabilir miyim?</h6>
                                    <p>Evet, her tarifin besin değerleri tarif sayfasında yer almaktadır. Bu bilgileri kullanarak, sağlıklı bir yemek hazırlama yolculuğunda size yardımcı olabiliriz.</p>

                                    <h6 className="font-weight-bold">Bir tarifi kaydedebilir miyim?</h6>
                                    <p>Evet, favori tariflerinizi kaydedebilirsiniz. Kaydedilen tarifleriniz, hesabınızda bulunan "Favori Tariflerim" bölümünde görüntülenebilir.</p>

                                </div>                        
                            </Col>
                            <Col className="col-12 text-center mt-3">
                                <GreenCTA text="Tarif Bul" redirect="/search" />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default FAQ;
