import './Footer.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { logout } from '../../store/actions/auth';
import instagram from './instagram.svg';
import logo from './logo.png';
import GoogleAd from '../GoogleAd';

function Footer(props) {
  const { authenticated } = props;
  return (
    <div>
      <div className='homepage-ad-div mt-5'>
        <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="1621026591"/>    
        <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="6923548065"/>    
      </div>
      <Container fluid className="position-relative pt-4 px-5">
        {/* <div style={{ height: '60px', width: '100%' }}>
          <GoogleAd className="wasteys-footer-ad" dataAdSlot="6923548065"/>  
        </div>   */}        
        <Link to="/" className="footer-logo">
          <img src={logo} alt="Logo" height="50px" />
        </Link>
        <Row className="footer-row" />
        <Row>
          <Col xs={12} sm={6} className="footer-col-1 py-2">
            <a
              className="footer-link w-100 float-left pb-1"
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/wasteysofficial"
            >
              {' '}
              <img src={instagram} alt="Instagram" className="pr-1" /> instagram.com/wasteys
            </a>
            <a className="footer-link w-100" href="mailto:info@wasteys.com">
              info@wasteys.com
            </a>
          </Col>
          <Col xs={12} sm={6} className="d-flex footer-col-2 align-items-center py-2">
            {authenticated ? (
              <div className="d-flex">
                <Link className="footer-link" to="/why">
                  Neden
                </Link>
                <Link className="footer-link" to="/how">
                  Nasıl
                </Link>
                <Link className="footer-link" to="/favorites">
                  Favorilerim
                </Link>
                <Link className="footer-link" to="/fridge">
                  Dolabım
                </Link>
                <Link
                  className="footer-link"
                  to="/"
                  onClick={() => {
                    props.logout();
                  }}
                >
                  Çıkış
                </Link>
              </div>
            ) : (
              <div className="d-flex">
                <Link className="footer-link" to="/why">
                  Neden
                </Link>
                <Link className="footer-link" to="/how">
                  Nasıl
                </Link>
                <Link className="footer-link" to="/search">
                  Yemek Bul
                </Link>
                <Link className="footer-link" to="/login">
                  Giriş
                </Link>
                <Link className="footer-link" to="/signup">
                  Üye Ol
                </Link>
              </div>
            )}
          </Col>
          <Col className='d-flex justify-content-center mb-2 col-12'>
            <div className="d-flex">
              <Link className="footer-link" to="/hakkimizda">
                Hakkımızda
              </Link>
              <Link className="footer-link" to="/sss">
                SSS
              </Link>
              <Link className="footer-link" to="/gizlilik-politikasi">
                Gizlilik Politikası
              </Link>
            </div>
          </Col>
          <Col className='d-flex justify-content-center'>
            <div className="d-flex">
              <Link className="footer-link" to="/kullanim-kosullari">
                Kullanım Koşulları
              </Link>
              <Link className="footer-link" to="/kvk">
               Kişisel Verilerin Korunması
              </Link>
              <Link className="footer-link" to="/cerez-politikasi">
                Çerez Politikası
              </Link>
              <Link className="footer-link" to="/uyelik-sozlesmesi">
                Üyelik Sözleşmesi
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="mb-1" />
        <p className="text-center py-0 my-1" style={{ fontSize: '12px', color: 'gray' }}>
          © wasteys {new Date().getFullYear()}
        </p>
      </Container>
    </div>
  );
}

const mapStateToProps = state => ({
  authenticated: state.auth.token !== null,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
