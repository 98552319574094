import { RECIPE_START, RECIPE_SUCCESS, RECIPE_FAIL, RECIPE_RESET } from "../actions/actionTypes";
import { updateObject } from "../utility";


const initialState = {
  recipe: [],
  error: null,
  loading: true,
  scroll: true,
  offset: 0,
  end: false,
  length: 0,
  categories: [],
};

const recipeStart = (state, action) => {
  return updateObject(state, {
    recipe: state.recipe,
    error: null,
    loading: true,
    scroll: false,

  });
};

const recipeSuccess = (state, action) => {
    if (action.data === "end"){
      return updateObject(state, {
        recipe: state.recipe,
        error: null,
        loading: false,
        scroll: false,
        end: true
      });
    } else {
      if (state.recipe.length > 0) {
        if (state.recipe[0]["id"] === action.data[0][0]["id"] && state.recipe[state.recipe.length - 1]["id"] === action.data[0][action.data[0].length - 1]["id"]) {
          return updateObject(state, {
            recipe: action.data[0],
            error: null,
            loading: false,
            scroll: true,
            offset: state.offset + 20,
            length: action.data[1],
            categories: action.data[2]
          });
        }
        else {
          return updateObject(state, {
            recipe: [...state.recipe, ...action.data[0]],
            error: null,
            loading: false,
            scroll: true,
            offset: state.offset + 20,
            length: action.data[1],
            categories: action.data[2]
          });
        }
      } else {
        return updateObject(state, {
          recipe: action.data[0],
          error: null,
          loading: false,
          scroll: true,
          offset: state.offset + 20,
          length: action.data[1],
          categories: action.data[2]
        });
      }
    }  
};

const recipeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    offset: 0,
    recipe: [],
    end: "",
  });
};


const recipeReset = (state, action) => {
  return updateObject(state, {
    loading: true,
    recipe: [],
    offset: 0,
    end: "",
    length: 0,
    categories: [],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIPE_START:
      return recipeStart(state, action);
    case RECIPE_SUCCESS:
      return recipeSuccess(state, action);
    case RECIPE_FAIL:
      return recipeFail(state, action);
    case RECIPE_RESET:
      return recipeReset(state, action);
    default:
      return state;
  }
};

export default reducer;
