import '../../assets/css/Confirmation.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import tomato from '../../assets/img/tomato-min.png';
import ButtonCTA from '../../components/buttonCTA/ButtonCTA';

function Confirmation(props) {
  const { confirmation } = props;
  if (!confirmation) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Container className="email-confirmation-container">
        <Row style={{ height: '100%' }}>
          <Col className="d-flex align-items-center">
            <div className="w-100">
              <h1 className="title email-homepage-title">
                Size bir e-posta gönderdik.
                <br /> Oradaki linke tıklayıp kaydınızı <br /> tamamlayabilirsiniz.
              </h1>
              <h2 className="sub-heading homepage-sub-heading">
                Evinizdeki malzemeler ile yapabileceğiniz 17 bin adet tarif paylaşıyoruz. Evet 17 bin tarif!
              </h2>
              <ButtonCTA text="Giriş Yap" redirect="/login" />
            </div>
          </Col>
        </Row>
        <img className="homepage-top-banner-image-email" src={tomato} alt="Tomato" />
      </Container>
      <div className="hp-1-spacer" />
    </div>
  );
}

const mapStateToProps = state => ({
  confirmation: state.auth.confirmation,
});

export default connect(mapStateToProps)(Confirmation);
