// import googleLogin from "../../components/googleLoginService";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: localStorage.getItem("token"),
  confirmation: localStorage.getItem("confirmation"),
  error: null,
  loading: false,
  resetConfirmation: false,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    confirmation: null,
  });
};

const authResetStart = (state, action) => {
  return updateObject(state, {
    token: null,
    confirmation: null,
    error: null,
  });
};

const passwordResetStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};
const passwordResetSuccess = (state, action) => {
  return updateObject(state, {
    resetConfirmation: true,
    loading: false,
    error: null,
  });
};
const passwordResetFail = (state, action) => {
  return updateObject(state, {
    resetConfirmation: false,
    loading: false,
    error: action.error.response.data.email
  });
};

const passwordResetRestart = (state, action) => {
  return updateObject(state, {
    resetConfirmation: false,
    loading: false,
    error: null,
  });
};

const passwordResetConfirmStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};
const passwordResetConfirmSuccess = (state, action) => {
  return updateObject(state, {
    resetConfirmation: true,
    loading: false,
    error: null,
  });
};
const passwordResetConfirmFail = (state, action) => {
  return updateObject(state, {
    resetConfirmation: false,
    loading: false,
    error: action.error.response.data.new_password2,
  });
};

const passwordResetConfirmRestart = (state, action) => {
  return updateObject(state, {
    resetConfirmation: false,
    loading: false,
    error: null,
  });
};

const authConfirmation = (state, action) => {
  return updateObject(state, {
    confirmation: action.confirmation,
    error: null,
    loading: false
  });
};

const authConfirmationLogin = (state, action) => {
  return updateObject(state, {
    confirmation: null,
  });
};

const googleLogin = (state, action) => {
  return updateObject(state, {
    token: null,
    error: null,
    loading: false
  });
};

const facebookLogin = (state, action) => {
  return updateObject(state, {
    token: null,
    error: null,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_CONFIRMATION:
      return authConfirmation(state, action);
    case actionTypes.AUTH_CONFIRMATION_LOGIN:
      return authConfirmationLogin(state, action);
    case actionTypes.AUTH_RESET_START:
      return authResetStart(state, action);
    case actionTypes.PASSWORD_RESET_START:
      return passwordResetStart(state, action);
    case actionTypes.PASSWORD_RESET_SUCCESS:
      return passwordResetSuccess(state, action);
    case actionTypes.PASSWORD_RESET_FAIL:
      return passwordResetFail(state, action);
    case actionTypes.PASSWORD_RESET_RESTART:
      return passwordResetRestart(state, action);
    case actionTypes.PASSWORD_RESET_CONFIRM_START:
      return passwordResetConfirmStart(state, action);
    case actionTypes.PASSWORD_RESET_CONFIRM_SUCCESS:
      return passwordResetConfirmSuccess(state, action);
    case actionTypes.PASSWORD_RESET_CONFIRM_FAIL:
      return passwordResetConfirmFail(state, action);
    case actionTypes.PASSWORD_RESET_CONFIRM_RESTART:
      return passwordResetConfirmRestart(state, action);
    case actionTypes.GOOGLE_LOGIN:
      return googleLogin(state, action);
    case actionTypes.FACEBOOK_LOGIN:
      return facebookLogin(state, action);
    default:
      return state;
  }
};

export default reducer;
