import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
  } from 'react-bootstrap';
import "./AuthGreen.css"
import whiteLogo from "./white-logo.png"


class AuthGreen extends Component {

render() {
    return (    
        <Container className="green-auth-container">
        <Row>
            <Col className="pt-3">
                <img className="" src={whiteLogo} alt="white-logo" width="120"/>   
                <h4 className="text-white">{this.props.title}</h4>
                <p className='text-white'>{this.props.text}</p>
            </Col>
        </Row>
      </Container>
    );
  }
}

export default AuthGreen;