import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class SignupContract extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Üyelik Sözleşmesi | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Üyelik Sözleşmesi | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Üyelik Sözleşmesi | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Üyelik Sözleşmesi | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Üyelik Sözleşmesi
                                    </h1>
                                    <p class="mt-3 mb-5 static-content">
                                        Sitemize üye olmadan önce aşağıda yer alan sözleşmeyi dikkatlice okuyunuz lütfen.<br/><br/>
                                        1. Taraflar<br/>
                                        a) www.wasteys.com internet sitesinin faaliyetlerini yürüten Mesa koru sitesi, Kamelya Blok Daire 18
                                        Çankaya / Ankara adresinde mukim Wasteys. (Bundan böyle WASTEYS olarak anılacaktır).<br/>
                                        b) www.wasteys.com internet sitesine üye olan internet kullanıcısı ("Üye")<br/><br/>
                                        2. Sözleşmenin Konusu<br/><br/>
                                        İşbu Sözleşme'nin konusu WASTEYS'nın sahip olduğu internet sitesi www.wasteys.com 'dan üyenin
                                        faydalanma şartlarının belirlenmesidir.<br/><br/>
                                        3. Tarafların Hak ve Yükümlülükleri<br/><br/>
                                        3.1. Üye, www.wasteys.com internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin kanunlar
                                        önünde doğru olduğunu, WASTEYS'nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm
                                        zararları aynen ve derhal tazmin edeceğini beyan ve taahhüt eder.<br/>
                                        3.2. Üye, WASTEYS tarafından kendisine verilmiş olan şifreyi başka kişi ya da kuruluşlara veremez,
                                        üyenin söz konusu şifreyi kullanma hakkı bizzat kendisine aittir. Bu sebeple doğabilecek tüm
                                        sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından WASTEYS'na karşı ileri sürülebilecek
                                        tüm iddia ve taleplere karşı, WASTEYS'nın söz konusu izinsiz kullanımdan kaynaklanan her türlü
                                        tazminat ve sair talep hakkı saklıdır.<br/>
                                        3.3. Üye www.wasteys.com internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve
                                        bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai
                                        yükümlülükler tamamen ve münhasıran üyeyi bağlayacaktır.<br/>
                                        3.4. Üye, www.wasteys.com internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı,
                                        başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının fikri ve telif
                                        haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını
                                        önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) ve işlemlerde bulunamaz.<br/>
                                        3.5. www.wasteys..com internet sitesinde üyeler tarafından beyan edilen, yazılan, kullanılan fikir ve
                                        düşünceler, tamamen üyelerin kendi kişisel görüşleridir ve görüş sahibini bağlar. Bu görüş ve
                                        düşüncelerin WASTEYS'yla hiçbir ilgi ve bağlantısı yoktur. WASTEYS'nın üyenin beyan edeceği fikir
                                        ve görüşler nedeniyle üçüncü kişilerin uğrayabileceği zararlardan ve üçüncü kişilerin beyan edeceği
                                        fikir ve görüşler nedeniyle üyenin uğrayabileceği zararlardan dolayı herhangi bir sorumluluğu
                                        bulunmamaktadır.<br/>
                                        3.6. WASTEYS, üye verilerinin yetkisiz kişilerce okunmasından ve üye yazılım ve verilerine
                                        gelebilecek zararlardan dolayı sorumlu olmayacaktır. Üye, www.wasteys.com internet sitesinin
                                        kullanılmasından dolayı uğrayabileceği herhangi bir zarar yüzünden WASTEYS’dan tazminat talep
                                        etmemeyi peşinen kabul etmiştir.<br/>
                                        3.7. Üye, diğer internet kullanıcılarının yazılımlarına ve verilerine izinsiz olarak ulaşmamayı veya
                                        bunları kullanmamayı kabul etmiştir. Aksi takdirde, bundan doğacak hukuki ve cezai sorumluluklar
                                        tamamen üyeye aittir.<br/>
                                        3.8. İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal
                                        nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, WASTEYS'nı bu ihlallerin hukuki ve cezai
                                        sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi
                                        halinde, WASTEYS'nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde
                                        bulunma hakkı saklıdır.<br/>
                                        3.9. WASTEYS'nın her zaman tek taraflı olarak gerektiğinde üyenin üyeliğini silme, müşteriye ait
                                        dosya, belge ve bilgileri silme hakkı vardır. Üye işbu tasarrufu önceden kabul eder. Bu durumda,
                                        WASTEYS'nın hiçbir sorumluluğu yoktur.<br/>
                                        3.10. www.wasteys.com internet sitesi yazılım ve tasarımı WASTEYS mülkiyetinde olup, bunlara ilişkin
                                        telif hakkı ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar üye tarafından
                                        izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu web sitesinde adı geçen başkaca şirketler
                                        ve ürünleri sahiplerinin ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında korunmaktadır.<br/>
                                        3.11. WASTEYS tarafından www.wasteys.com internet sitesinin iyileştirilmesi, geliştirilmesine yönelik
                                        olarak ve/veya yasal mevzuat çerçevesinde siteye erişmek için kullanılan İnternet servis
                                        sağlayıcısının adı ve Internet Protokol (IP) adresi, Siteye erişilen tarih ve saat, sitede bulunulan
                                        sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web sitesinin Internet adresi gibi
                                        birtakım bilgiler toplanabilir.<br/>
                                        3.12. WASTEYS, üyenin kişisel bilgilerini yasal bir zorunluluk olarak istendiğinde veya (a) yasal
                                        gereklere uygun hareket etmek veya WASTEYS’na tebliğ edilen yasal işlemlere uymak; (b)
                                        WASTEYS ve WASTEYS web sitesi ailesinin haklarını ve mülkiyetini korumak ve savunmak için
                                        gerekli olduğuna iyi niyetle kanaat getirdiği hallerde açıklayabilir.<br/>
                                        3.13. WASTEYS web sitesinin virus ve benzeri amaçlı yazılımlardan arındırılmış olması için mevcut
                                        imkanlar dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması için kullanıcının, kendi
                                        virus koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması gerekmektedir. Bu bağlamda
                                        üye WASTEYS web sitesi'ne girmesiyle, kendi yazılım ve işletim sistemlerinde oluşabilecek tüm hata
                                        ve bunların doğrudan yada dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul etmiş sayılır.<br/>
                                        3.14. WASTEYS, sitenin içeriğini dilediği zaman değiştirme, kullanıcılara sağlanan herhangi bir
                                        hizmeti değiştirme yada sona erdirme veya WASTEYS web sitesinde kayıtlı kullanıcı bilgi ve verilerini
                                        silme hakkını saklı tutar.<br/>
                                        3.15. WASTEYS, üyelik sözleşmesinin koşullarını hiçbir şekil ve surette ön ihbara ve/veya ihtara gerek
                                        kalmaksızın her zaman değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen yada
                                        yürürlükten kaldırılan her hüküm , yayın tarihinde tüm üyeler bakımından hüküm ifade edecektir.<br/>
                                        3.16. Taraflar, WASTEYS'na ait tüm bilgisayar kayıtlarının tek ve gerçek münhasır delil olarak, HUMK
                                        madde 287'ye uygun şekilde esas alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği
                                        hususunu kabul ve beyan eder.<br/>
                                        3.17. WASTEYS, iş bu üyelik sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta
                                        adreslerine bilgilendirme mailleri ve cep telefonlarına bilgilendirme SMS'leri gönderme yetkisine
                                        sahip olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla beraber bilgilendirme maillerinin
                                        elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna gönderilmesini kabul etmiş
                                        sayılacaktır.<br/><br/>
                                        4. Sözleşmenin Feshi<br/><br/>
                                        İşbu sözleşme üyenin üyeliğini iptal etmesi veya WASTEYS tarafından üyeliğinin iptal edilmesine
                                        kadar yürürlükte kalacaktır. WASTEYS üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi
                                        durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir.<br/><br/>
                                        5. ihtilaflarin Halli<br/><br/>
                                        İşbu sözleşmeye ilişkin ihtilaflarda İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.<br/><br/>
                                        6. Yürürlük<br/><br/>
                                        Üyenin, üyelik kaydı yapması üyenin üyelik sözleşmesinde yer alan tüm maddeleri okuduğu ve üyelik
                                        sözleşmesinde yer alan maddeleri kabul ettiği anlamına gelir. İşbu Sözleşme üyenin üye olması
                                        anında akdedilmiş ve karşılıklı olarak yürürlülüğe girmiştir.
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default SignupContract;
