import React from "react";
import "./why.css"
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class Why extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Neden | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Elinizdeki malzemeleri neden çöpe atmamalısınız? Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz. Bu rakam dünya genelinde 1.3 milyar ton gıda." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Neden | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Neden | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Neden | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container fluid className="why-row mt-4">
                        <Container>
                            <Row >
                                <Col className="py-5 px-4">
                                    <h1 className="title" style={{ fontSize: "2rem", color: "white", letterSpacing:"0.08rem"}}>
                                    Çünkü,
                                    </h1>
                                    <p className="sub-heading" style={{ color: "white", fontWeight:"300" }}>
                                    “...Dünya’da her sene 9 milyon insan açlık sebebiyle hayatını kaybediyor ve bu insanların hayatta kalmaları için gereken gıda miktarı sadece 5 milyon ton. Sadece Türkiye’deki atık miktarının %20’si bile bu ölümleri bitirmeye yeter.
                                    <br></br><br></br>Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz. Bu rakam dünya genelinde 1.3 milyar ton gıda.”
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h2 className="text-center">
                                    Dünya için bir şey yapmak istiyorsanız, atmayın!
                                    </h2>
                                    <p className="sub-heading text-center">
                                    Evinize giren hiçbir gıda malzemesinin çöpe atılmasını sizin gibi biz de istemiyoruz. <br></br>
                                    Bunun için evinizdeki malzemeleri seçip daha önce denemediğiniz lezettleri tadabilirsiniz.
                                    </p>
                                    <GreenCTA text="Hemen Seç" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default Why;
