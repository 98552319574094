import '../../assets/css/ResetPassword.css';

import React from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import og from '../../assets/img/og.jpg';
import AuthGreen from '../../components/authenticationGreenCol/AuthGreen';
import { passwordReset, passwordResetRestart } from '../../store/actions/auth';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.props.passwordResetRestart();
  }

  handleReset(e) {
    e.preventDefault();
    const { email } = this.state;
    this.props.passwordReset(email);
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    const { token, error, loading, resetConfirmation } = this.props;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <div className="login-outer-div d-flex align-items-center">
        <Helmet>
          ‍<title>Şifrenizi Sıfırlayın | Wasteys</title>‍
          <meta
            name="description"
            content="Elinizdeki malzemeleri seçin ve  17 bin tarif arasından yapabileceğiniz yemekleri görün. Seçtiğiniz malzemeleri dolabınıza ekleyin, dilediğiniz zaman ekleyip çıkartın. Beğendiğiniz tarifleri favorilerinize ekleyin."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />
          <meta name="twitter:title" content="Şifrenizi Sıfırlayın | Wasteys" />
          <meta name="twitter:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta name="twitter:image" content={og} />
          <meta property="og:title" content="Şifrenizi Sıfırlayın | Wasteys" />
          <meta property="og:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta property="og:image" content={og} />
          <meta property="og:url" content="https://wasteys.com/reset-password/" />
          <meta property="og:site_name" content="Şifrenizi Sıfırlayın | Wasteys" />
          <meta property="og:locale" content="tr_TR" />
          <meta property="og:type" content="article" />
        </Helmet>
        <Container className="my-5">
          <Row className="mx-auto login-row ">
            <Col className="p-0" md={5}>
              <AuthGreen
                className="p-3"
                title="Atıksız Bir Dünya Düşünün"
                text="Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz."
                redirect="/fridge"
                page="Dolabım"
                source="tomatos"
              />
            </Col>
            <Col className="py-md-5 py-4" md={7}>
              <Container className="">
                <Row className="mx-auto">
                  <Col>
                    <h5 className="mt-1 mb-3">Şifrenizi Sıfırlayın</h5>
                    {error && [
                      Array.isArray(error) ? (
                        error.map((key, index) => {
                          if (error === 'E-mail is not verified.') {
                            return (
                              <p key="1" className="auth-form-p mb-3">
                                Lütfen e-postanızı onaylayın.
                              </p>
                            );
                          }
                          if (error === 'Incorrect input. access_token or code is required.') {
                            return (
                              <p key="2" className="auth-form-p mb-3">
                                Geçersiz giriş, lütfen tekrar deneyiniz.
                              </p>
                            );
                          }
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <p key={index} className="auth-form-p mb-3">
                              {' '}
                              {key}{' '}
                            </p>
                          );
                        })
                      ) : (
                        <p className="auth-form-p mb-3"> Lütfen daha sonra tekrar deneyiniz. </p>
                      ),
                    ]}
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="mx-auto">
                  <Col>
                    <Form onSubmit={this.handleReset}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="E-Posta Adresiniz"
                          required
                          onChange={this.handleChange}
                          name="email"
                        />
                      </Form.Group>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-4">
                          <Spinner animation="border" role="status" style={{ color: '#319DA4' }}>
                            <span className="sr-only ">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <Button className="auth-button mt-3" variant="primary" type="submit">
                          Sıfırlama E-Postası Gönder
                        </Button>
                      )}
                    </Form>
                    {resetConfirmation && (
                      <p className="reset-form-p mt-3 mb-0">
                        Sıfırlama linki e-postanıza gönderildi. Lütfen e-posta kutunuzu kontrol ediniz.
                      </p>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  error: state.auth.error,
  loading: state.auth.loading,
  resetConfirmation: state.auth.resetConfirmation,
});

const mapDispatchToProps = dispatch => ({
  passwordReset: email => dispatch(passwordReset(email)),
  passwordResetRestart: () => dispatch(passwordResetRestart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
