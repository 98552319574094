import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col
} from 'react-bootstrap';
import tomatos from '../assets/img/tomatos.jpg'; 
import soup from '../assets/img/soup.jpg'; 
import money from '../assets/img/money.jpg'; 




class WhiteBanner extends Component {

render() {
    const buttonstyle = {
        color: "#319DA4",
        backgroundColor: "white",
        padding: "0",
        fontWeight: "600",
        border: 'none',
        borderRadius: '0',
        borderBottom: '1px solid #319ca4'
      };
    let iconObj = {
        "tomatos" : tomatos,
        "soup" : soup,
        "money" : money,
    }
    return (    
        <Row className="v-center mb-5">
            <Col sm={6} className="text-center">                
                <img style={{ width: '100%', maxWidth: '400px' }} src={iconObj[this.props.source]} alt={this.props.source} />                 
            </Col>
            <Col className="hv-center py-3">
                <div className="w-100 ">
                    <h4>{this.props.title}</h4>
                    <p>{this.props.text}</p>
                    <Link to={this.props.redirect}>
                        <Button style={buttonstyle}>{this.props.page}</Button>
                    </Link>
                </div>
            </Col>            
        </Row>  
        
    );
  }
}

export default WhiteBanner;