import '../assets/css/Favorite.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';

import dishes from '../assets/img/dishes.gif';
import og from '../assets/img/og.jpg';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import ListItem from '../components/ListItem';
import Recipe from '../components/recipe/Recipe';
import { favoriteToggleURL, getFavoriteURL, getRecipeURL } from '../constants';
import Error from './Error';

function Favorite() {
  const [sort, setSort] = useState('id');
  const [sortText, setSortText] = useState('Önerilen Sıralama');
  const [category, setCategory] = useState('Bütün Kategoriler');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();

  const [recipes, setRecipes] = useState([]);
  const [favoritesLength, setFavoritesLength] = useState();
  const [favoritesCategories, setFavoritesCategories] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const isAuthenticated = localStorage.getItem('token');
  const chosenItems = JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : [];

  useEffect(() => {
    if (isAuthenticated) {
      fetchFavorite(sort, category);
      fetchRecipe(chosenItems, 0, sort, category);
    }
  }, []);

  const fetchFavorite = (sort, category) => {
    axios
      .post(
        getFavoriteURL,
        { sort, category },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(res => {
        setFavorites(res.data[0]);
        setFavoritesCategories(res.data[1]);
        setFavoritesLength(res.data[2]);
      })
      .catch(err => {});
  };

  const fetchRecipe = (items, offset, sort, category) => {
    setLoading(true);
    axios
      .post(getRecipeURL, {
        items: items.map(ele => ele.name),
        offset,
        sort,
        category,
      })
      .then(res => {
        if (res.data !== 'end') {
          setRecipes(res.data[0].slice(0, 6));
        }
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSortDropdown = e => {
    if (e.value === 'En Az Süre') {
      setSort('total_prep_time');
      setSortText('En Az Süre');
      fetchFavorite('total_prep_time', category);
    }
    if (e.value === 'En Fazla Süre') {
      setSort('-total_prep_time');
      setSortText('En Fazla Süre');
      fetchFavorite('-total_prep_time', category);
    }
    if (e.value === 'En Az Malzeme') {
      setSort('item_list_len');
      setSortText('En Az Malzeme');
      fetchFavorite('item_list_len', category);
    }
    if (e.value === 'En Fazla Malzeme') {
      setSort('-item_list_len');
      setSortText('En Fazla Malzeme');
      fetchFavorite('-item_list_len', category);
    }
  };

  const handleCategoryDropdown = e => {
    setCategory(e.value);
    fetchFavorite(sort, e.value);
  };

  const handleCloseCategory = () => {
    setCategory('Bütün Kategoriler');
    fetchFavorite(sort, 'Bütün Kategoriler');
  };

  const handleToggleFavorite = id => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      axios
        .post(
          favoriteToggleURL,
          { id },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          fetchFavorite(sort, category);
        })
        .catch(err => {
          setError(err);
        });
    }
  };

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <Helmet>
        ‍<title>Favoriler | Wasteys</title>‍
        <meta
          name="description"
          content="Elinizdeki malzemelerle bulduğunuz ve favorilediğiniz tariflerinizi görüntüleyin. Kolayca ekleyip kaldırabilirsiniz. Tarifleri kategorilerine göre listeleyebilir, malzeme sayısına ya da süresine göre sıralayabilirsiniz."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Favoriler | Wasteys" />
        <meta name="twitter:description" content="Favorilerinize eklediğiniz tarifleri görüntüleyin." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Favoriler | Wasteys" />
        <meta property="og:description" content="Favorilerinize eklediğiniz tarifleri görüntüleyin." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/favorite/" />
        <meta property="og:site_name" content="Favoriler | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <BottomMenu
        className="d-block d-sm-none"
        chosenItemsLength={chosenItems.length}
      />
      {error ? (
        <Error />
      ) : (
        <Container fluid className="search-card-container mb-4 mt-3 px-4">
          <Row>
            <Col>
              <ListItem
                handleToggleFavorite={handleToggleFavorite}
                ids={favorites.map(ele => ele.id)}
                handleSortDropdown={handleSortDropdown}
                favoriteSort={sort}
                recipe={favorites}
                favoriteText="Favori tariflerinizde"
                amount={favoritesLength}
                sortText={sortText}
                category={category}
                handleCategoryDropdown={handleCategoryDropdown}
                categories={favoritesCategories}
                handleCloseCategory={handleCloseCategory}
              />
            </Col>
          </Row>
          {loading ? (
            <Col className="d-flex justify-content-center align-items-center mt-2">
              <div className="text-center">
                <h4 className="pb-3">Favori tarifleriniz bulunuyor...</h4>
                <img className="mt-2" src={dishes} height="200" width="auto" alt="dish gif" />
              </div>
            </Col>
          ) : (
            [
              favoritesLength < 3 && [
                recipes.length > 1 ? (
                  <Row>
                    <Col
                      className="d-flex justify-content-center align-items-center pt-4 pb-4 col-12"
                      style={{ minHeight: '50vh' }}
                    >
                      <div className="text-center">
                        <h4 className="text-center pb-2">Aşağıdaki tariflere göz atabilirsin!</h4>
                        <p className="sub-heading text-center">
                          Seçtiğin malzemelerle yapabileceğin aşağıdaki tarifleri beğenip, favoriler listene hemen
                          alabilirsin.
                        </p>
                      </div>
                    </Col>
                    {recipes
                      .filter(ele => !favorites.map(ele => ele.id).includes(ele.id))
                      .slice(0, 6)
                      .map(dish => (
                        <Recipe onToggleFavorite={handleToggleFavorite} key={dish.id} dish={dish} />
                      ))}
                    <Col className="d-flex justify-content-center align-items-center pt-4 col-12">
                      <div className="text-center">
                        <h4 className="text-center pb-2">Daha Fazla Tarif</h4>
                        <p className="sub-heading text-center">
                          Yapabileceğin daha fazla tarif görmek için hemen &quot;Yemek Bul&quot;a tıkla
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-center text-center search-page-button mb-2">
                      <Link
                        to="/result"
                        className="navbar-button-3 m-0 w-100"
                        style={{ minWidth: '140px', maxWidth: '160px' }}
                      >
                        Yemek Bul
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  <Row key="row">
                    <Col className="d-flex justify-content-center align-items-center pt-4 col-12">
                      <div className="text-center">
                        <h4 className="text-center pb-2">Beğendiğin bir tarif bulamadık :(</h4>
                        <p className="sub-heading text-center">
                          Malzeme seçerek favorilerine ekleyebileceğin tarifleri görebilirsin.
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-center text-center search-page-button mb-2">
                      <Link
                        to="/search"
                        className="navbar-button-3 m-0 w-100"
                        style={{ minWidth: '140px', maxWidth: '160px' }}
                      >
                        Malzeme Seç
                      </Link>
                    </Col>
                  </Row>
                ),
              ],
            ]
          )}
        </Container>
      )}
    </div>
  );
}

export default Favorite;
