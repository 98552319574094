import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";
import * as actions from "./store/actions/auth";
import CustomLayout from "./containers/Layout";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import './App.css';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

function ScrollToTopOnOpen() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <Router>
        <ScrollToTopOnOpen/>
        <CustomLayout {...this.props}>
          <BaseRouter />
        </CustomLayout>
        <ScrollToTop/>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  // const token = localStorage.getItem("token");
  return {
    isAuthenticated: state.auth.token !== null
    // isAuthenticated: token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);