import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class KK extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Kullanım Koşulları | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Kullanım Koşulları | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Kullanım Koşulları | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Kullanım Koşulları | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Kullanım Koşulları
                                    </h1>
                                    <p class="mt-3 mb-5 static-content">
                                        <strong>Sitede Yer Alan Bilgiler</strong>
                                        <br/>Bu web sitesinde yer alan bilgiler, genel bilgi amaçlıdır ve kesinlikle yatırım tavsiyesi veya herhangi bir hukuki veya ticari tavsiye vermek amacıyla kullanılmamalıdır. Bu bilgiler, doğruluğu, güncelliği, eksiksizliği veya uygunluğu konusunda hiçbir şekilde garanti vermez. <br/>
                                        <br/>
                                        <strong>Site Kullanımı</strong>
                                        <br/>Bu web sitesini kullanarak, web sitesinin kullanımı için gerekli olan bilgisayar donanımı, yazılım ve internet bağlantısı gibi gereksinimlerin sizin sorumluluğunuzda olduğunu kabul edersiniz. Ayrıca, bu siteyi kullanarak, herhangi bir yasadışı veya hileli amaç için kullanmayacağınızı, siteye zarar veremeyeceğinizi, siteye müdahale etmeyeceğinizi veya başka bir kullanıcının kullanımını engelleyemeyeceğinizi kabul edersiniz. <br/>
                                        <br/>
                                        <strong>Telif Hakkı ve Fikri Mülkiyet Hakları</strong>
                                        <br/>Bu web sitesinde yer alan her türlü bilgi, materyal, yazılım, tasarım, grafik, logolar, ticari markalar ve diğer fikri mülkiyet hakları, site sahibinin mülkiyetindedir veya sahibinden lisans alınmıştır. Bu materyallerin izinsiz kullanımı yasaktır. <br/>
                                        <br/>
                                        <strong>Üçüncü Taraf Web Siteleri</strong>
                                        <br/>Bu web sitesinde, üçüncü taraf web sitelerine bağlantılar yer alabilir. Bu bağlantılar yalnızca bilgi amaçlıdır ve bu üçüncü taraf sitelerinin içerikleri veya doğruluğu konusunda hiçbir sorumluluğumuz yoktur. <br/>
                                        <br/>
                                        <strong>Sorumluluk Sınırlamaları</strong>
                                        <br/>Bu web sitesini kullanarak veya bu siteden edinilen bilgilere dayanarak yapılacak herhangi bir işlem veya yatırımın sonuçlarından, doğrudan veya dolaylı olarak sorumlu değiliz. <br/>
                                        <br/>
                                        <strong>Değişiklikler</strong>
                                        <br/>Bu kullanım şartları, herhangi bir zamanda önceden bildirimde bulunmaksızın değiştirilebilir. Bu nedenle, bu sayfayı düzenli olarak kontrol etmeniz önerilir. <br/>
                                        <br/>
                                        <strong>Kabul</strong>
                                        <br/>Bu kullanım şartlarını kabul ederek, bu web sitesindeki tüm kullanım şartlarını tam olarak anladığınızı ve kabul ettiğinizi beyan edersiniz.
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default KK;
