import '../assets/css/ChosenPage.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CheckCircle, PlusCircle, Trash2 } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import GoogleAd from '../components/GoogleAd';

import og from '../assets/img/og.jpg';
import sort from '../assets/img/sort.png';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import IsAuthenticatedModal from '../components/isAuthenticationModal/IsAuthenticatedModal';
import { addToFridgeURL, getFridgeURL, itemDeleteURL } from '../constants';
import Error from './Error';

const _ = require('lodash');

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const categories = [
  { id: 'G', name: 'Tahıl' },
  { id: 'D', name: 'Süt ve Süt Ürünleri' },
  { id: 'M', name: 'Et ve Et Ürünleri' },
  { id: 'L', name: 'Bakliyat' },
  { id: 'F', name: 'Meyve & Sebze ve Kuruyemiş' },
  { id: 'S', name: 'Baharatlar ve Diğer Malzemeler' },
];

const modalMessage = 'Seçtiğiniz malzemeyi dolabınıza ekleyebilmeniz için';

function ChosenPage(props) {
  const [show, setShow] = useState(false);
  const isAuthenticated = localStorage.getItem('token');
  const [fridgeItems, setFridgeItems] = useState([]);

  const [error, setError] = useState(false);
  const [chosenItems, setChosenItems] = useState(JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : []);

  const [itemsToBeSorted, setItemsToBeSorted] = useState({
    G: false,
    D: false,
    M: false,
    L: false,
    F: false,
    S: false,
  });

  const handleClose = () => setShow(false);

  useEffect(() => {
    localStorage.setItem('itemsChosen', JSON.stringify(chosenItems));
  }, [chosenItems]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchItemsFridge();
    }
  }, []);

  const handleDeselectAll = id => {
    const itemsLeft = chosenItems.filter(el => el.category !== id);
    setChosenItems(itemsLeft);
  };

  const handleDeleteItem = item => {
    const itemsLeft = chosenItems.filter(el => el.name !== item.name);
    setChosenItems(itemsLeft);
  };

  const fetchItemsFridge = () => {
    axios
      .get(getFridgeURL, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(res => {
        setFridgeItems(res.data.items);
      })
      .catch(err => {
        setError(err);
      });
  };

  const handleAddToFridge = name => {
    if (isAuthenticated) {
      const ingredients = [name];
      axios
        .post(
          addToFridgeURL,
          {
            ingredients,
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          fetchItemsFridge();
        })
        .catch(err => {
          setError(err);
        });
    } else {
      setShow(true);
    }
  };

  const handleRemoveFromFridge = name => {
    if (isAuthenticated) {
      const ids = [];
      fridgeItems.forEach(element => {
        if (element.name !== name) {
          ids.push(element.id);
        }
      });
      axios
        .post(
          itemDeleteURL,
          { ids },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          fetchItemsFridge();
        })
        .catch(err => {
          setError(err);
        });
    } else {
      setShow(true);
    }
  };

  const handleSort = categoryID => {
    const updatedItemsToBeSorted = { ...itemsToBeSorted };
    updatedItemsToBeSorted[categoryID] = true;
    setItemsToBeSorted(updatedItemsToBeSorted);
  };

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div>
      <Helmet>
        ‍<title>Malzeme Seçin | Wasteys</title>‍
        <meta
          name="description"
          content="400 malzeme arasından evinizdeki malzemeleri seçin, Yemek Bul butonuna tıklayın ve 17 bin tarif arasından yapabileceğiniz tarifleri görün. Üye olarak seçtiğiniz malzemeleri dolabınıza ekleyin."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Malzeme Seçin | Wasteys" />
        <meta name="twitter:description" content="Evinizdeki malzemeleri seçin ve yapabileceğiniz tarifleri görün." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Malzeme Seçin | Wasteys" />
        <meta property="og:description" content="Evinizdeki malzemeleri seçin ve yapabileceğiniz tarifleri görün." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/search/" />
        <meta property="og:site_name" content="Malzeme Seçin | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container id="cont">
        <Row>
          <Col className="text-center mt-4 ">
            <h4 className="title mb-3 mobile-title">Seçtiğim Malzemeler</h4>
            <BottomMenu chosenItems={chosenItems} chosenItemsLength={chosenItems.length} />
          </Col>
        </Row>
      </Container>
      {error ? (
        <Error />
      ) : (
        <Container fluid className="search-card-container">
          <Row className="mt-1 d-flex justify-content-center">
            {categories?.map((category, ind) => {
              const sortedChosenItems = [...chosenItems];
              if (itemsToBeSorted[category.id]) {
                sortedChosenItems.sort((a, b) => new Intl.Collator('tr').compare(a.name, b.name));
              }
              return (
                <Col key={category.id} className="col-12 col-md-4 mb-4">
                  <Card className="rounded-0">
                    <Card.Header className="item-card-header-mobile-chosen rounded-0" as={Card.Header}>
                      {category.name}
                      <button
                        className="m-0 p-0 float-right"
                        onClick={() => handleSort(category.id)}
                        style={{ border: 'none', backgroundColor: 'white' }}
                        type="button"
                      >
                        <img className="" src={sort} alt="sort" height="16px" />
                      </button>
                    </Card.Header>
                    <Card.Body>
                      <Row className="px-2">
                        {sortedChosenItems
                          .filter(f => f.category === category.id)
                          .map(ing => (
                            <Col className="px-1 col-12 mb-2" key={ing.name}>
                              <label
                                htmlFor={ing.name}
                                id="input"
                                className="non-checked-label w-100 d-flex align-items-center"
                                style={{ cursor: 'default' }}
                              >
                                {capitalizeFirstLetter(ing.name)}
                                <div className="ml-auto d-flex align-items-center" style={{ color: '#525252' }}>
                                  {fridgeItems.map(ele => ele.name).includes(ing.name) ? (
                                    <CheckCircle
                                      size={19}
                                      className="mr-5"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleRemoveFromFridge(ing.name)}
                                    />
                                  ) : (
                                    <PlusCircle
                                      size={19}
                                      className="mr-5"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleAddToFridge(ing.name)}
                                    />
                                  )}

                                  <Trash2
                                    size={19}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleDeleteItem(ing)}
                                  />
                                </div>
                              </label>
                            </Col>
                          ))}
                      </Row>
                      <button
                        className="mobile-item-card-button-chosen mt-2"
                        type="button"
                        onClick={() => handleDeselectAll(category.id)}
                      >
                        Hepsini Sil
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <IsAuthenticatedModal show={show} handleClose={handleClose} modalMessage={modalMessage} />
        </Container>
      )}
      <div className='homepage-ad-div my-4'>
        <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="7704887513"/>    
        <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="2631652090"/>    
      </div>
    </div>
  );
}

export default ChosenPage;
