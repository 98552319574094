import axios from "axios";
import * as actionTypes from "./actionTypes";
import {
  localhost,
  localhostAdmin,
} from "../../constants";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("confirmation");
  localStorage.removeItem("expirationDate");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const authConfirmationLogin = () => {
  localStorage.removeItem("confirmation");
  return {
    type: actionTypes.AUTH_CONFIRMATION_LOGIN
  };
};

export const authConfirmation = confirmation => {
  return {
    type: actionTypes.AUTH_CONFIRMATION,
    confirmation: confirmation
  };
};

export const authResetStart = () => {
  return {
    type: actionTypes.AUTH_RESET_START
  };
};

export const passwordResetStart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_START
  };
};

export const passwordResetSuccess = () => {
  return {
    type: actionTypes.PASSWORD_RESET_SUCCESS
  };
};

export const passwordResetFail = error =>  {
  return {
    type: actionTypes.PASSWORD_RESET_FAIL,
    error: error
  };
};

export const passwordResetRestart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_RESTART
  };
};

export const passwordResetConfirmStart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_CONFIRM_START
  };
};

export const passwordResetConfirmSuccess = () => {
  return {
    type: actionTypes.PASSWORD_RESET_CONFIRM_SUCCESS
  };
};

export const passwordResetConfirmFail = error =>  {
  return {
    type: actionTypes.PASSWORD_RESET_CONFIRM_FAIL,
    error: error
  };
};

export const passwordResetConfirmRestart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_CONFIRM_RESTART
  };
};

export const googleLogin = token => {
  return {
    type: actionTypes.GOOGLE_LOGIN,
    token: token
  };
};

export const facebookLogin = token => {
  return {
    type: actionTypes.FACEBOOK_LOGIN,
    token: token
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const checkError = error => {
  const errors = {
    msg: error.response.data,
    status: error.response.status
  }
  return {
    type: actionTypes.GET_ERRORS,
    payload: errors,

  };
};

export const authLogin = (email, password) => {
  return dispatch => {
    dispatch(authStart());
      axios
        .post(localhostAdmin + "/rest-auth/login/", {
          email: email,
          password: password
        })
        .then(res => {
          const token = res.data.key;
          const expirationDate = new Date(new Date().getTime() + 3600 * 5000000);
          localStorage.setItem("token", token);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
          dispatch(authConfirmationLogin());
        })
        .catch(err => {
          dispatch(authFail(Object.values(err.response.data)));
          // dispatch(checkError(err))
        });
  };
};

export const passwordReset = (email) => {
  return dispatch => {
      dispatch(passwordResetStart())
      axios
        .post(localhostAdmin + "/rest-auth/password/reset/", {
          email: email
        })
        .then(res => {
          dispatch(passwordResetSuccess())
        })
        .catch(err => {
          dispatch(passwordResetFail(err))
        });
  };
};

export const passwordResetConfirm = (uid, token, password1, password2) => {
  return dispatch => {
      dispatch(passwordResetConfirmStart())

      axios
        .post(localhostAdmin + "/rest-auth/password/reset/confirm/" ,{
          uid: uid,
          token: token,
          new_password1: password1,
          new_password2: password2,
        },
        )
        .then(res => {
          dispatch(passwordResetConfirmSuccess())
        })
        .catch(err => {
          dispatch(passwordResetConfirmFail(err))
        });
  };
};

export const googleAuth = (response) => {
  return dispatch => {
    dispatch(googleLogin());
    if (response.accessToken){
      axios
        .post(localhost + "/rest-auth/google/",
        {
          access_token: response.accessToken,
        })
        .then(res => {
          const token = res.data.key;
          const expirationDate = new Date(new Date().getTime() + 3600 * 5000000);
          localStorage.setItem("token", token);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
          dispatch(authFail(err));
        });
    }
  };
};

export const facebookAuth = (accesstoken) => {
  return dispatch => {
    dispatch(facebookLogin());
    axios
      .post(localhost  + "/rest-auth/facebook/",
      {
        access_token: accesstoken,
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 3600 * 5000000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
        dispatch(checkError(err))
      });
  };
};



export const authSignup = (email, password1, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(localhostAdmin + "/rest-auth/registration/", {
        email: email,
        password1: password1,
        password2: password2
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 3600 * 5000000);
        if (token !== undefined){
          localStorage.setItem("token", token);
          localStorage.setItem("expirationDate", expirationDate); 
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
        }        
        localStorage.setItem("confirmation", "to_be_confirmed");               
        dispatch(authConfirmation("to_be_confirmed"));
        
      })
      .catch(err => {
        dispatch(authFail(Object.values(err.response.data)));
        // dispatch(checkError(err))
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    const confirmation = localStorage.getItem("confirmation");
    if (confirmation === undefined) {
      dispatch(logout());
    }
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
