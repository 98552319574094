import '../assets/css/Fridge.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Trash2 } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import og from '../assets/img/og.jpg';
import sortImage from '../assets/img/sort.png';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import { getFridgeURL, itemDeleteURL } from '../constants';
import Error from './Error';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const categories = [
  { id: 'G', name: 'Tahıl' },
  { id: 'D', name: 'Süt ve Süt Ürünleri' },
  { id: 'M', name: 'Et ve Et Ürünleri' },
  { id: 'L', name: 'Bakliyat' },
  { id: 'F', name: 'Meyve & Sebze ve Kuruyemiş' },
  { id: 'S', name: 'Baharatlar ve Diğer Malzemeler' },
];

function FridgeItems() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [fridgeItems, setFridgeItems] = useState([]);
  const isAuthenticated = localStorage.getItem('token');
  const [itemsToBeSorted, setItemsToBeSorted] = useState({
    G: false,
    D: false,
    M: false,
    L: false,
    F: false,
    S: false,
  });
  const chosenItems = JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : [];

  useEffect(() => {
    fetchItemsFridge();
  }, []);

  const fetchItemsFridge = (ids) => {
    if(!ids) {
      setLoading(true);
    }    
    axios
      .get(getFridgeURL, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(res => {
        setFridgeItems(res.data.items);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveCategoryFromFridge = categoryID => {
    const ids = [];
    fridgeItems.forEach(element => {
      if (element.category !== categoryID) {
        ids.push(element.id);
      }
    });
    handleRemoveFromFridge(ids);
  };

  const handleRemoveFridgeItem = itemID => {
    const ids = [];
    fridgeItems.forEach(element => {
      if (element.id !== itemID) {
        ids.push(element.id);
      }
    });
    handleRemoveFromFridge(ids);
  };

  const handleRemoveFromFridge = ids => {
    axios
      .post(
        itemDeleteURL,
        { ids },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(res => {
        fetchItemsFridge(ids);
      })
      .catch(err => {
        setError(err);
      });
  };

  const handleSort = categoryID => {
    const updatedItemsToBeSorted = { ...itemsToBeSorted };
    updatedItemsToBeSorted[categoryID] = true;
    setItemsToBeSorted(updatedItemsToBeSorted);
  };

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <Helmet>
        ‍<title>Dolabım | Wasteys</title>‍
        <meta
          name="description"
          content="Dolabınızdaki malzemeleri ve bu malzemelerle yapabileceğiniz yemekleri görüntüleyin. Malzemeleri ekleyip çıkartabilirsiniz. Tarifleri kategorilerine göre listeleyebilir, malzeme sayısına ya da süresine göre sıralayabilirsiniz."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Dolabım | Wasteys" />
        <meta name="twitter:description" content="Dolabınızdaki malzemeleri görüntüleyin." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Dolabım | Wasteys" />
        <meta property="og:description" content="Dolabınızdaki malzemeleri görüntüleyin." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/fridge/" />
        <meta property="og:site_name" content="Dolabım | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container fluid className="search-card-container">
        <Row>
          <Col className="text-center mt-4 ">
            <h4 className="title mb-3 mobile-title">Dolabımdaki Malzemeler</h4>
          </Col>
        </Row>
        {error ? (
          <Error />
        ) : (
          <Row className="mt-1 d-flex justify-content-center">
            {categories?.map(category => {
                const sortedChosenItems = [...fridgeItems];
                if (itemsToBeSorted[category.id]) {
                  sortedChosenItems.sort((a, b) => new Intl.Collator('tr').compare(a.name, b.name));
                }
                return (
                  <Col key={category.id} className="col-12 col-md-4 mb-4">
                    <Card className="rounded-0">
                      <Card.Header className="item-card-header-mobile-chosen rounded-0" as={Card.Header}>
                        {category.name}
                        <button
                          className="m-0 p-0 float-right"
                          onClick={() => handleSort(category.id)}
                          style={{ border: 'none', backgroundColor: 'white' }}
                          type="button"
                        >
                          <img className="" src={sortImage} alt="sort" height="16px" />
                        </button>
                      </Card.Header>
                      <Card.Body>
                        {loading ? (
                            <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                              <Spinner animation="border" variant="info" />
                            </Row>
                          ) : (
                        <Row className="px-2">
                          {sortedChosenItems
                            .filter(f => f.category === category.id)
                            .map(ing => (
                              <Col className="px-1 col-12 mb-2" key={ing.name}>
                                <label
                                  htmlFor={ing.name}
                                  id="input"
                                  className="non-checked-label w-100 d-flex align-items-center"
                                  style={{ cursor: 'default' }}
                                >
                                  {capitalizeFirstLetter(ing.name)}
                                  <div className="ml-auto d-flex align-items-center" style={{ color: '#525252' }}>
                                    <Trash2
                                      size={19}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleRemoveFridgeItem(ing.id)}
                                    />
                                  </div>
                                </label>
                              </Col>
                            ))}
                        </Row>
                        )}
                        <button
                          className="mobile-item-card-button-chosen mt-2"
                          type="button"
                          onClick={() => handleRemoveCategoryFromFridge(category.id)}
                        >
                          Hepsini Dolabımdan Sil
                        </button>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            }
          </Row>
        )}
      </Container>
      <BottomMenu
        className="d-block d-sm-none"
        chosenItemsLength={chosenItems.length}
      />
    </div>
  );
}

export default FridgeItems;
