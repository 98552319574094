import '../assets/css/ListItem.css';
import 'react-dropdown/style.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import { useHistory, useLocation } from 'react-router-dom';

import { favoriteToggleURL, getFavoriteURL } from '../constants';
import Error from '../containers/Error';
import Recipe from './recipe/Recipe';

function ListItem(props) {
  const sortItems = ['En Az Süre', 'En Fazla Süre', 'En Az Malzeme', 'En Fazla Malzeme'];
  const [ids, setIds] = useState([]);
  const isAuthenticated = localStorage.getItem('token');
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState();

  useEffect(() => {
    if (isAuthenticated && !location.pathname.includes('favorite')) {
      fetchFavorite(props.favoriteSort, props.category);
    }
  }, []);

  const fetchFavorite = (sort, category) => {
    if (isAuthenticated) {
      axios
        .post(
          getFavoriteURL,
          { sort, category },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          handleFavoriteID(res.data[0]);
        })
        .catch(err => {
          setError(err);
        });
    }
  };

  const handleFavoriteID = favorites => {
    const favoriteIDs = [];
    favorites.forEach(element => favoriteIDs.push(element.id));
    setIds(favoriteIDs);
  };

  const handleToggleFavorite = id => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      axios
        .post(
          favoriteToggleURL,
          { id },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          fetchFavorite(props.favoriteSort, props.category);
        })
        .catch(err => {
          setError(err);
        });
    }
  };

  return (
    <div>
      <Row>
        <Col className='px-1'>
          <h1 className="recipe-amount-text mt-1">
            {props.resultText}
            {props.fridgeText}
            {props.favoriteText}
            <strong style={{ fontSize: '14px' }}> {props.amount}</strong> tarif bulunmaktadır.
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className='col-6 px-1'>
          <Dropdown
            className="float-left tarif-kategorisi-dropdown"
            value={props.category}
            options={props.categories.map(element => element)}
            onChange={props.handleCategoryDropdown}
          />
          </Col>
          <Col className='col-6 px-1'>
          <Dropdown
            className="tarif-kategorisi-dropdown"
            options={sortItems}
            onChange={props.handleSortDropdown}
            placeholder={props.sortText}
          />
        </Col>
      </Row>
      {props.category !== 'Bütün Kategoriler' && (
        <Row>
          <span className="category-popup">
            {props.category} &nbsp;&nbsp;
            <span
              onClick={props.handleCloseCategory}
              style={{ cursor: 'pointer', color: 'white', fontSize: '15px', lineHeight: '0' }}
            >
              &#10005;
            </span>{' '}
          </span>
        </Row>
      )}
      {error ? (
        <Error />
      ) : (
        <Row>
          {props.recipe?.map((dish, idx) => (
            <Recipe
              key={dish.id}
              onToggleFavorite={props.handleToggleFavorite ? props.handleToggleFavorite : handleToggleFavorite}
              dish={dish}
              favoriteIDs={props.ids ? props.ids : ids}
              idx={idx}
            />
          ))}
        </Row>
      )}
      {props.end && !props.favoriteText && (
        <Row>
          <Col className="text-center">
            <h1 className="recipe-amount-text mt-1">Daha fazla tarif bulunamadı.</h1>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ListItem;
