import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { googleAuth } from '../../store/actions/auth';
import { connect } from "react-redux";
import "./GoogleSocialAuth.css"

class GoogleSocialAuth extends Component {
  state = {
    accessToken: ""
  }

  handleGoogleResponse = (response) => {
    this.setState({ accessToken: response.accessToken})
    this.props.googleResponse(response)
  }

  render() {
    return (     
        <GoogleLogin className="google-button"
          clientId='923374255072-8uj8lndhisrq7kd4k9t578va61vgptt2.apps.googleusercontent.com'
          buttonText={this.props.text}
          onSuccess={this.handleGoogleResponse}
          onFailure={this.handleGoogleResponse}
          cookiePolicy={'single_host_origin'}
        />
    );
  }
}


const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    errors: state.errors,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    googleResponse: (response) => dispatch(googleAuth(response))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleSocialAuth);