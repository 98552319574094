import { ITEMS_CHOSEN_START, ITEMS_CHOSEN_SUCCESS, ITEMS_CHOSEN_FAIL } from "../actions/actionTypes";
import { updateObject } from "../utility";

let itemsChosenSet = []
if (localStorage.getItem('itemsChosen') === null){
    localStorage.setItem('itemsChosen', JSON.stringify(itemsChosenSet))
    localStorage.setItem('offset', 0)
}

const initialState = {
  itemsChosen: JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : [],
  error: null,
  loading: false
};

const itemsChosenStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const itemsChosenSuccess = (state, action) => {
  return updateObject(state, {
    itemsChosen: action.data,
    error: null,
    loading: false
  });
};

const itemsChosenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_CHOSEN_START:
      return itemsChosenStart(state, action);
    case ITEMS_CHOSEN_SUCCESS:
      return itemsChosenSuccess(state, action);
    case ITEMS_CHOSEN_FAIL:
      return itemsChosenFail(state, action);
    default:
      return state;
  }
};

export default reducer;
