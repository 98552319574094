import React from "react";
import "./nomatchpage.css"
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import og_img from '../../assets/img/og.jpg';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


class Why extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center background-no-match">
                <Helmet>‍
                    <title>404 :( | Wasteys</title>‍
                    <meta name="description" content="Elinizdeki malzemeleri seçin ve  17 bin tarif arasından yapabileceğiniz yemekleri görün. Seçtiğiniz malzemeleri dolabınıza ekleyin, dilediğiniz zaman ekleyip çıkartın. Beğendiğiniz tarifleri favorilerinize ekleyin." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="404 :( | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemelerle yapabileceğiniz yemekleri görün." />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="404 :( | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemelerle yapabileceğiniz yemekleri görün." />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:site_name" content="404 :( | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0 py-md-3 py-2">
                    <Container>
                        <Row >
                            <Col className="px-4">
                                <h1 className="text-white">
                                ÜZGÜNÜZ, GEÇERSİZ SAYFA :(
                                </h1>
                                <p className="sub-heading" style={{ color: "white", fontWeight:"300" }}>
                                Aradığınız sayfayı bulamadık ama bizden vazgeçmenizi istemiyoruz. Aşağıdaki butona tıklayarak, malzeme seçebilir ve anında 17 bin tarif arasından yapabileceğiniz tarifleri görebilirsiniz.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-3">       
                            <Col className="d-flex justify-content-center text-center search-page-button">
                                <Link to="/search" className="no-match-button m-0 w-100" style={{minWidth:"140px", maxWidth:"160px"}}>Malzeme Seç</Link>
                            </Col>      
                        </Row> 
                    </Container>
                </Container>
            </div>
        );
    }
}export default Why;
