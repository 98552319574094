import React, { useEffect } from 'react';


function Recipe(props) {
useEffect(() => {
    try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch (e) {
    }
  
  },[]);
  return (
    <ins
    className={`adsbygoogle ${props.className}`}
    data-ad-client="ca-pub-8599014895430482"
    data-ad-slot={props.dataAdSlot}
    data-full-width-responsive={props.fullWidthResponsive || 'true'}
    />
  );
}

export default Recipe;
