import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class About extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Hakkımızda | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Hakkımızda | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Hakkımızda | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Hakkımızda | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Hakkımızda
                                    </h1>
                                    <p>Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir. Amacımız, gıda israfını azaltmak ve insanları evlerindeki tüm malzemeleri kullanmaya teşvik etmektir. Yemek yapmak herkesin sevdiği bir aktivitedir, ancak zaman zaman mutfağımızda kullanmadığımız malzemeler birikir ve çöpe gitmek zorunda kalır. Wasteys, bu soruna çözüm olarak evlerimizdeki malzemeleri kullanarak yapabileceğimiz yüzlerce tarif sunar.</p>
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Nasıl Çalışır?
                                    </h1>
                                    <p>Wasteys'e kaydolabilir ve bu sayede bir buzdolabı edinebilirsiniz. Buzdolabınızda bulunan malzemeleri kaydederek, istediğiniz zaman tariflerinizi oluşturabilirsiniz. Tarifler, saniyeler içinde hazır hale gelir ve size malzemelerin hazırlık zamanını, kaç kişilik olduğunu, gereken malzemelerin miktarını ve tarif kategorisini gösterir.</p>
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Neler Yapabilirsiniz?
                                    </h1>
                                    <p className="mb-5">Wasteys'te 17.000'den fazla tarif bulunmaktadır ve 6 farklı kategoride (Tahıllar, Süt Ürünleri, Et, Sebzeler & Meyveler & Kuruyemişler, Baharatlar & Otlar & Diğerleri) malzemelerinizi seçebilirsiniz. Tarifleri kategoriye göre filtreleyebilir ve hazırlama süresine veya gereken malzemelerin miktarına göre sıralayabilirsiniz. Ayrıca, favori tariflerinizi kaydedebilir ve istediğiniz zaman görüntüleyebilirsiniz. 
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default About;
