/* eslint-disable no-nested-ternary */
import '../assets/css/Fridge.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import GoogleAd from '../components/GoogleAd';

import dishes from '../assets/img/dishes.gif';
import og from '../assets/img/og.jpg';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import ButtonCTA from '../components/buttonCTA/ButtonCTA';
import ItemsChosen from '../components/itemsChosen/ItemsChosen';
import ListItem from '../components/ListItem';
import { getFridgeURL, getRecipeURL, itemDeleteURL } from '../constants';
import Error from './Error';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function Fridge() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fridgeItems, setFridgeItems] = useState([]);
  const [sort, setSort] = useState('id');
  const [sortText, setSortText] = useState('Önerilen Sıralama');
  const [category, setCategory] = useState('Bütün Kategoriler');
  const [offset, setOffset] = useState(0);
  const [recipes, setRecipes] = useState([]);
  const [end, setEnd] = useState(false);
  const [recipesLength, setRecipesLength] = useState();
  const [recipesCategories, setRecipesCategories] = useState([]);
  const isAuthenticated = localStorage.getItem('token');
  const chosenItems = JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : [];

  window.onscroll = () => {
    if (window.location.pathname.includes('fridge')) {
      if (
        document.documentElement.scrollHeight - document.documentElement.scrollTop <
          document.documentElement.clientHeight + 1900 &&
        document.documentElement.scrollHeight > 2000 &&
        !end &&
        !loading
      ) {
        fetchRecipe(fridgeItems, offset, sort, category);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchItemsFridge();
    }
  }, []);

  useEffect(() => {
    if (fridgeItems.length > 0) {
      fetchRecipe(fridgeItems, offset, sort, category);
    }
  }, [fridgeItems]);

  const fetchRecipe = (items, offset, sort, category, deletedItem) => {
    setLoading(true);
    axios
      .post(getRecipeURL, {
        items: items.map(ele => ele.name),
        offset,
        sort,
        category,
      })
      .then(res => {
        if (res.data !== 'end') {
          const recipesList = !deletedItem ? [...recipes] : [];
          setRecipes([...recipesList, ...res.data[0]]);
          setRecipesLength(res.data[1]);
          setRecipesCategories(res.data[2]);
          setOffset(offset + 20);
        } else {
          setEnd(true);
        }
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchItemsFridge = () => {
    setLoading(true);
    axios
      .get(getFridgeURL, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(res => {
        setFridgeItems(res.data.items);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveFridgeItems = items => {
    const ids = [];
    fridgeItems.forEach(element => {
      items.forEach(ele => {
        if (element.name === ele.name) {
          ids.push(element.id);
        }
      });
    });
    setEnd(false);
    setOffset(0);
    handleRemoveFromFridge(ids);
  };

  const handleRemoveFromFridge = ids => {
    axios
      .post(
        itemDeleteURL,
        { ids },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(res => {
        fetchItemsFridge();
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setRecipes([]);
      });
  };

  const handleSortDropdown = e => {
    if (e.value === 'En Az Süre') {
      setSort('total_prep_time');
      setSortText('En Az Süre');
      fetchRecipe(fridgeItems, 0, 'total_prep_time', category, true);
    }
    if (e.value === 'En Fazla Süre') {
      setSort('-total_prep_time');
      setSortText('En Fazla Süre');
      fetchRecipe(fridgeItems, 0, '-total_prep_time', category, true);
    }
    if (e.value === 'En Az Malzeme') {
      setSort('item_list_len');
      setSortText('En Az Malzeme');
      fetchRecipe(fridgeItems, 0, 'item_list_len', category, true);
    }
    if (e.value === 'En Fazla Malzeme') {
      setSort('-item_list_len');
      setSortText('En Fazla Malzeme');
      fetchRecipe(fridgeItems, 0, '-item_list_len', category, true);
    }
    setEnd(false);
    setOffset(0);
  };

  const handleCategoryDropdown = e => {
    setCategory(e.value);
    fetchRecipe(fridgeItems, 0, sort, e.value, true);
    setOffset(0);
    setEnd(false);
  };

  const handleCloseCategory = () => {
    setCategory('Bütün Kategoriler');
    fetchRecipe(fridgeItems, 0, sort, 'Bütün Kategoriler', true);
    setEnd(false);
    setOffset(0);
  };

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <Helmet>
        ‍<title>Dolabım | Wasteys</title>‍
        <meta
          name="description"
          content="Dolabınızdaki malzemeleri ve bu malzemelerle yapabileceğiniz yemekleri görüntüleyin. Malzemeleri ekleyip çıkartabilirsiniz. Tarifleri kategorilerine göre listeleyebilir, malzeme sayısına ya da süresine göre sıralayabilirsiniz."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Dolabım | Wasteys" />
        <meta name="twitter:description" content="Dolabınızdaki malzemeleri görüntüleyin." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Dolabım | Wasteys" />
        <meta property="og:description" content="Dolabınızdaki malzemeleri görüntüleyin." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/fridge/" />
        <meta property="og:site_name" content="Dolabım | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <BottomMenu
        className="d-block d-sm-none"
        chosenItemsLength={chosenItems.length}
      />
      <Container fluid className="search-card-container mb-4 mt-3 px-4">
          <Row>
            <Col sm={6} md={4} lg={3} xl={3} className="d-none d-sm-block">
              <ItemsChosen
                strong="Dolabınızdaki"
                text="Malzemeler"
                items={fridgeItems}
                source="fridge"
                buttonText="Malzeme Seç"
                removeFridgeItem={handleRemoveFridgeItems}
              />
            </Col>
            <Col sm={6} md={8} lg={9} xl={9}>
                <ListItem
                  recipe={recipes}
                  fridgeText="Dolabınızdaki malzemelerle yapabileceğiniz"
                  favoriteSort="id"
                  amount={recipesLength}
                  handleSortDropdown={handleSortDropdown}
                  sortText={sortText}
                  category={category}
                  handleCategoryDropdown={handleCategoryDropdown}
                  categories={recipesCategories}
                  handleCloseCategory={handleCloseCategory}
                />
                {loading &&
                <Col className="d-flex justify-content-center align-items-center mt-3">
                  <div className="text-center">
                    <h3 className="pb-2" style={{ fontFamily: 'Open Sans' }}>17,000&apos;den fazla tarif araştırılıyor...</h3>
                    <img className="mt-2" src={dishes} height="200" width="auto" alt="dish-gif" />
                  </div>
                </Col>
                }
              </Col>
              {error && <Error /> } 
              {recipes.length === 0 && !loading && !error &&
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <h2 className="text-center pb-2">Dolabınızdaki malzemelerle yapabileceğiniz tarif bulamadık :(</h2>
                    <h2 className="sub-heading text-center">
                      Daha fazla malzeme seçerek 17 bin tarif arasından yapabileceğiniz tarif sayısını artırabilirsiniz.
                    </h2>
                    <ButtonCTA text="Malzeme Seç" style={{ width: '300px' }} redirect="/search" />
                  </div>
                </Col>
                }
          </Row>
        </Container>
        <div className='homepage-ad-div my-4'>
          <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="2248508710"/>    
          <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="7513315821"/>    
        </div>
    </div>
  );
}

export default Fridge;
