import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Button
} from 'react-bootstrap';
import "./GreenCTA.css"


class GreenCTA extends Component {

render() {
    const buttonstyle = {
        color: "white",
        backgroundColor: "#319DA4",
        border: "none",
        borderRadius: "0",
        padding: "0.7rem",
        width: "140px",
      };
    return (    
        <Link to={this.props.redirect}>
            <Button className="greenCTA" style={buttonstyle}>{this.props.text}</Button>
        </Link>
    );
  }
}

export default GreenCTA;