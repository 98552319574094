import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    msg: {},
    status: null
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        msg: {}
    });
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_ERRORS:
            return updateObject(state, {
                msg: action.payload.msg,
                status: action.payload.status
            });
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        default:
            return state;
    }
}