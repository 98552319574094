import './BottomMenu.css';

import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Archive, Coffee, Heart, List } from 'react-feather';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { addToFridgeURL } from '../../constants';
import ButtonCTA from '../buttonCTA/ButtonCTA';
import IsAuthenticatedModal from '../isAuthenticationModal/IsAuthenticatedModal';

const modalMessages = {
  goToFridge: 'Dolabınızı görüntüleyebilmeniz için',
  fridgeitems: 'Seçtiğiniz malzemeleri dolabınıza ekleyebilmeniz için',
  favorite: 'Favori tariflerinizi görüntüleyebilmeniz için',
};

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function BottomMenu(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const isAuthenticated = localStorage.getItem('token');

  const handleClose = () => setShow(false);

  const history = useHistory();

  const handleAddToFridge = window => {
    if (isAuthenticated) {
      const ingredients = props.chosenItems.map(v => v.name.toLowerCase());
      axios
        .post(
          addToFridgeURL,
          {
            ingredients,
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            if (window === 'mobile') {
              history.push('/fridgeitems');
            } else {
              history.push('/fridge');
            }
          }
        });
    } else {
      setShow(true);
      setModalMessage(modalMessages.fridgeitems);
    }
  };

  const handleNavigationButton = path => {
    if (isAuthenticated) {
      if(path === 'goToFridge') {
        history.push('fridgeitems');
      } else {
        history.push(path);
      }      
    } else {
      setShow(true);
      setModalMessage(modalMessages[`${path}`]);
    }
    return null;
  };

  return (
    <Container className={`yemek-dolap-button ${props.className}`} style={{ zIndex: '6' }}>
      {!location.pathname.includes('result') &&
        !location.pathname.includes('fridge') &&
        !location.pathname.includes('favorite') && (
          <Row className="my-2 my-sm-0 mt-sm-4">
            <Col xs={6} className="pr-1 pl-2 text-sm-right">
              <Button
                onClick={() => handleAddToFridge('mobile')}
                className="inline-buttons outline CTA d-block d-sm-none bottom-menu-CTA"
              >
                Dolabıma Ekle
              </Button>
              <Button
                onClick={() => handleAddToFridge('desktop')}
                className="inline-buttons outline CTA d-none d-sm-block float-right"
              >
                Dolabıma Ekle
              </Button>
            </Col>
            <Col xs={6} className="pl-1 pr-2 text-sm-left">
              <ButtonCTA
                text="Yemek Bul"
                redirect="/result"
                className="inline-buttons green bottom-menu-CTA"
                linkClassName="mobile-inline-buttons"
              />
            </Col>
          </Row>
        )}
      {location.pathname === '/fridgeitems' && (
        <Row className="my-2">
          <Col xs={12} className="px-2 text-sm-left">
            <ButtonCTA
              text="Dolabımdaki Malzemelerle Tarif Bul"
              redirect="/fridge"
              className="inline-buttons green bottom-menu-CTA"
              linkClassName="mobile-inline-buttons"
            />
          </Col>
        </Row>
      )}
      <Row className="pt-1 d-flex d-sm-none text-center" style={{ borderTop: '2px solid #319DA4' }}>
        <Col xs={3}>
          <Link to="/chosen" >
            <div style={location.pathname.includes('chosen') ? { color: '#319DA4', position: 'relative' } : {color: '#696969', position: 'relative'}} >
              <div className="circle-info">{props.chosenItemsLength < 100 ? props.chosenItemsLength : '+99'}</div>
              <List size={20}/>
              <p style={{ fontSize: '11px' }} className=" mb-1">
                Seçtiklerim
              </p>
            </div>
          </Link>
        </Col>
        <Col xs={3} className="px-0">
          <Link to="/search" style={{ color: '#696969' }}>
            <div style={location.pathname.includes('search') ? { color: '#319DA4' } : {color: '#696969'}}>
              <Coffee size={20} />
              <p style={{ fontSize: '11px' }} className=" mb-1">
                Malzeme Seç
              </p>
            </div>
          </Link>
        </Col>
        <Col xs={3}>
          <div onClick={() => handleNavigationButton('favorite')} style={{ cursor: 'pointer' }}>
            <div style={location.pathname.includes('favorite') ? { color: '#319DA4' } : {color: '#696969'}}>
              <Heart size={20} />
              <p style={{ fontSize: '11px' }} className=" mb-1">
                Favorilerim
              </p>
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div onClick={() => handleNavigationButton('goToFridge')} style={{ color: '#696969', cursor: 'pointer' }}>
            <div style={location.pathname.includes('fridgeitems') ? { color: '#319DA4' } : {color: '#696969'}}>
              <Archive size={20} />
              <p style={{ fontSize: '11px' }} className=" mb-1">
                Dolabım
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <IsAuthenticatedModal show={show} handleClose={handleClose} modalMessage={modalMessage} />
    </Container>
  );
}

export default BottomMenu;
