import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class KVK extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Kişisel Verilerin Korunması | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Kişisel Verilerin Korunması | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Kişisel Verilerin Korunması | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Kişisel Verilerin Korunması | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="">
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    WASTEYS KİŞİSEL VERİLERİN KORUNMASI KANUNU AYDINLATMA METNİ
                                    </h1>
                                    <p class="mt-3 mb-5 static-content">
                                    Wasteys kişisel verilerinizin hukuka uygun olarak toplanması, saklanması ve paylaşılmasını sağlamak
                                    ve gizliliğinizi korumak amacıyla mümkün olan en üst seviyede güvenlik tedbirlerini almaktadır.
                                    Amacımız; 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi gereğince ve sizlerin
                                    memnuniyeti doğrultusunda, kişisel verilerinizin alınma şekilleri, işlenme amaçları, paylaşılan kişiler,
                                    hukuki nedenleri ve haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.<br/><br/>
                                    a) Veri Sorumlusu<br/>
                                    6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı Kanun”) uyarınca, kişisel verileriniz; veri
                                    sorumlusu olarak WASTEYS (“ŞİRKET”) tarafından aşağıda açıklanan kapsamda toplanmakta ve
                                    işlenmektedir.<br/><br/>
                                    b) Kişisel Verilerin Hangi Amaçla İşleneceği<br/>
                                    ŞİRKET tarafından, müşterileri, çalışanları, potansiyel müşterileri, çalışan adayları, resmî kurum
                                    temsilcileri ve çalışanları, iş ortakları ve tedarikçilerinden;
                                    Kimlik bilgisi, iletişim bilgisi, müşteri bilgisi, müşteri işlem bilgisi, sağlık bilgisi, performans bilgisi,
                                    işlem güvenliği bilgisi, hukuki işlem ve uyum bilgisi ile pazarlama satış bilgisi kategorilerinde özel
                                    nitelikli kişisel veri ve kişisel veri (İkisi birlikte “Kişisel Veri” olarak anılacaktır.) toplanmaktadır.
                                    Toplanan kişisel verileriniz;<br/>
                                    - ŞİRKET ürün ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan yükümlülüklerimizin yerine
                                    getirilmesi, kayıt ve belgelerin düzenlenebilmesi, yerel ve uluslararası yasal mevzuatın öngördüğü
                                    bilgi saklama, raporlama, bilgilendirme, vergi ve sair yükümlülüklere uymak,<br/>
                                    - Hizmet ve ürünlerin kalitesinin artırılmasına yönelik yapılacak olan satış ve pazarlama faaliyetleri
                                    için yapılacak size özel reklam, kampanya, avantajlar ve faydalar sunmak,<br/>
                                    - Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, bu
                                    hizmet ve ürünlere ilişkin olarak sizlere gerekli bilgilerin aktarılması amacıyla iletişim kurmak,<br/>
                                    - Satış ve pazarlama faaliyetleri için yapılacak trafik ölçümleme, istatistiki analizler,<br/>
                                    Segmentasyon/profilleme ve CRM çalışmalarını yürütmek,<br/>
                                    - Müşteri memnuniyetinin ölçümlenmesi ve artırılması, şikâyet yönetimi, yeni hizmet ve ürünler ile
                                    ilgili görüş ve önerilerinizi almak, sorun-hata bildirimlerinizi almak, ürün ve hizmetlere, şikayet ve
                                    taleplerinize yönelik tarafınıza bilgi vermek,<br/>
                                    - Tarafınızla iletişime geçilmesi, siparişlerinizi almak, ödeme işlemlerinizi gerçekleştirmek, 3. Kişiler
                                    ile lojistik iş birliği sağlayıp ürün gönderimini sağlamak, ilginizi çekebilecek ürün ve hizmetleri tespit
                                    edip bu doğrultuda size öneriler yapmak, promosyon ve hediye gönderimi yapmak, çevrimiçi
                                    davranışlarınızı analiz ederek size özel reklamlar göstermek, size sağladığımız hizmetin kalitesinin
                                    ölçülmesi ve geliştirilmesi, tarafımızca belirlenen segmentlere göre profilleme ve skorlama yapılacak
                                    müşteri portföyümüzün yönetilmesi,<br/>
                                    - Karşılaştırmalı ürün ve/veya hizmet teklifi, modelleme, mevcut veya yeni ürün çalışmaları ve/veya
                                    geliştirmeleri, kişisel verilerinizi ŞİRKET’e açıklamanıza konu olan ŞİRKET ana sözleşmesinde yazılı
                                    olan işleri düzenleyen kanun ve ilgili mevzuat kapsamında sizlere sunulacak her türlü ürün ve
                                    hizmetlerde kullanılmak,<br/>
                                    - Resmî kurumlarca öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak,
                                    sözleşmelerin gerekliliklerini yerine getirmek ve bu hizmetlerden faydalanılmasına ilişkin olarak
                                    ŞİRKET’in tabi olduğu yasal yükümlülükleri ifa etmek,<br/>
                                    - ŞİRKET’in ticari ve iş stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda; ŞİRKET
                                    tarafından yürütülen finans operasyonları, iletişim, pazar araştırması ve sosyal sorumluluk aktiviteleri,
                                    satın alma operasyonları (talep, teklif, değerlendirme, sipariş, bütçelendirme, sözleşme), şirket içi
                                    sistem ve uygulama yönetimi operasyonları, hukuki operasyonları yönetmek<br/>
                                    - Resmi makamlardan veya sizlerden gelen talepleri incelemek, değerlendirmek ve yanıtlamak,
                                    amaçlarıyla işlenmektedir.<br/><br/>
                                    c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği<br/><br/>
                                    Toplanan kişisel verileriniz; yukarıda yer alan b) bölümünde belirtilen işleme amaçlarının
                                    gerçekleştirilmesi ile sınırlı ve tamamı için geçerli olmak üzere;<br/>
                                    - ŞİRKET’in iş ortaklarına, hissedarlarına, iştiraklerine,<br/>
                                    - Vergi Usul Kanunu, Sosyal Güvenlik Kurumu mevzuatı, Sayıştay, Suç Gelirlerinin Aklanmasının
                                    Önlenmesi Hakkında Kanun, Karaparanın Aklanmasının Önlenmesine Dair Kanun, Türk Ticaret
                                    Kanunu, Borçlar Kanunu ve diğer mevzuat hükümlerinin izin verdiği kişi veya kuruluşlara,<br/>
                                    - Kanunen yetkili kamu kurum ve kuruluşları, idari merciler ve yasal mercilere,<br/>
                                    - Yurt dışı şirketlerine ve iştiraklerine,<br/>
                                    - Ürün/hizmet karşılaştırma, analiz, değerlendirme, reklam ve yukarıda belirtilen amaçların
                                    gerçekleştirilmesinde hizmet aldığımız, işbirliği yaptığımız gerçek veya tüzel kişilere, program ortağı
                                    kurum ve kuruluşlara, müşterilerimize gönderdiğimiz iletilerin gönderilmesi konusunda anlaşmalı
                                    olduğumuz kurumlara, verilen siparişlerin size teslimini gerçekleştiren kargo şirketlerine
                                    aktarılmaktadır.<br/><br/>
                                    ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi<br/><br/>
                                    Kişisel Verileriniz, hem tamamen veya kısmen otomatik yollarla hem de veri kayıt sisteminin parçası
                                    olan ancak otomatik olmayan yöntemlerle;<br/>
                                    Doğrudan sizin tarafınızdan veya sizin adınıza hareket etmeye yetkili kişiler tarafından; anlaşmalı
                                    internet siteleri üzerinden yapılmış olan başvurular, mağaza ve ŞİRKET genel müdürlük personeli,
                                    destek hizmeti verdiğimiz / aldığımız kurumlar ile her türlü mevzuat veya sözleşme dahilinde işlem
                                    yapılan gerçek ve / veya tüzel kişiler ve doğrudan otomatik sistemler (İnternet sitemiz ve mobil
                                    uygulamamız, çağrı merkezlerimiz, sosyal medya hesaplarımız) ile sözlü, yazılı veya elektronik
                                    ortamlarda;<br/>
                                    Kanun’un 5. ve 6. maddesinde belirtilmiş olan;<br/>
                                    - Kanunlarda açıkça öngörülmüş olması,<br/>
                                    - Şirketimizin hukuki yükümlülüğü yerine getirebilmek için zorunlu olması,<br/>
                                    - Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olup işlemenin gerekli olması,<br/>
                                    - Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, Şirketimizin meşru menfaati için işlemenin
                                    zorunlu olması,<br/>
                                    - Tarafınızca alenileştirilmiş olması,<br/>
                                    - Bir hakkın tesisi, kullanılması veya korunması için işlemenin zorunlu olması,<br/>
                                    - Açık rızanızın bulunması<br/>
                                    Şeklindeki hukuki sebeplere dayanarak toplanmaktadır.<br/><br/>
                                    d) Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. Maddesinde Sayılan Hakları<br/><br/>
                                    Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda düzenlenen yöntemlerle iletmeniz
                                    durumunda ŞİRKET talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde
                                    sonuçlandıracaktır. Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde
                                    şirketimiz tarafından CD ,Flash bellek gibi kayıt ortamı kişisel veri sahibinden talep edilecektir.
                                    Bu kapsamda kişisel veri sahipleri;<br/>
                                    ● Kişisel veri işlenip işlenmediğini öğrenme,<br/>
                                    ● Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,<br/><br/>
                                    ● Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,<br/>
                                    ● Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,<br/>
                                    ● Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu
                                    kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,<br/>
                                    ● 6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,<br/>
                                    işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok
                                    edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere
                                    bildirilmesini isteme,<br/>
                                    ● İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                                    aleyhine bir sonucun ortaya çıkmasına itiraz etme,<br/>
                                    ● Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın
                                    giderilmesini talep etme haklarına sahiptir.<br/>
                                    Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı Kanunu’nun 13. maddesinin 1.
                                    fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
                                    Tebliğ gereğince Türkçe ve yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik
                                    imza, mobil imza ya da ŞİRKET’e daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik
                                    posta adresini kullanmak suretiyle iletebilirsiniz. Başvurularda sadece başvuru sahibi kişi hakkında
                                    bilgi verilecek olup diğer aile fertleri ve üçüncü kişiler hakkında bilgi alınması mümkün olmayacaktır.
                                    ŞİRKET’in cevap vermeden önce kimliğinizi doğrulama hakkı saklıdır.<br/>
                                    Başvurunuzda;<br/>
                                    a) Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,<br/>
                                    b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancı iseniz uyruğunuzun,<br/>
                                    pasaport numaranızın veya varsa kimlik numaranızın,<br/>
                                    c) Tebligata esas yerleşim yeri veya iş yeri adresinizin,<br/>
                                    ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,<br/>
                                    d) Talep konunuzun, bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya
                                    eklenmesi gerekmektedir.<br/>
                                    Yazılı olarak yapmak istediğiniz başvurularınızı, gerekli belgeleri ekleyerek veri sorumlusu olarak
                                    Şirketimizin, Mesa Koru Sitesi, Kamelya Blok, Daire 18 Çankaya / Ankara adresine verebilirsiniz.
                                    E-posta yoluyla yapmak istediğiniz başvurularınızı info@wasteys.com e-posta adresine
                                    yapabilirsiniz. KEP yoluyla yapmak istediğiniz başvurularınızı info@wasteys.com KEP adresimize
                                    yapabilirsiniz.<br/>
                                    Talebinizin niteliğine göre kimlik tespitine olanak sağlayacak bilgi ve belgelerin eksiksiz ve doğru
                                    olarak tarafımıza sağlanması gerekmektedir. İstenilen bilgi ve belgelerin gereği gibi sağlanmaması
                                    durumunda, ŞİRKET tarafından talebinize istinaden yapılacak araştırmaların tam ve nitelikli şekilde
                                    yürütülmesinde aksaklıklar yaşanabilecektir. Bu durumda, ŞİRKET kanuni haklarını saklı tuttuğunu
                                    beyan eder. Bu nedenle, başvurunuzun talebinizin niteliğine göre eksiksiz ve istenilen bilgileri ve
                                    belgeleri içerecek şekilde gönderilmesi gerekmektedir.<br/>
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default KVK;
