/* eslint-disable no-nested-ternary */
import '../assets/css/ResultPage.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import dishes from '../assets/img/dishes.gif';
import og from '../assets/img/og.jpg';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import ButtonCTA from '../components/buttonCTA/ButtonCTA';
import ItemsChosen from '../components/itemsChosen/ItemsChosen';
import ListItem from '../components/ListItem';
import { getRecipeURL } from '../constants';
import Error from './Error';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function ResultPage() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('id');
  const [sortText, setSortText] = useState('Önerilen Sıralama');
  const [category, setCategory] = useState('Bütün Kategoriler');
  const [end, setEnd] = useState(false);
  const [offset, setOffset] = useState(0);
  const [recipes, setRecipes] = useState([]);
  const [recipesLength, setRecipesLength] = useState();
  const [recipesCategories, setRecipesCategories] = useState([]);
  const [recipeItems, setRecipeItems] = useState(JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : []);

  window.onscroll = () => {
    if (window.location.pathname.includes('result')) {
      if (
        document.documentElement.scrollHeight - document.documentElement.scrollTop <
          document.documentElement.clientHeight + 1900 &&
        document.documentElement.scrollHeight > 2000 &&
        !end &&
        !loading
      ) {
        fetchRecipe(recipeItems, offset, sort, category);
      }
    }
  };

  useEffect(() => {
    fetchRecipe(recipeItems, offset, sort, category);
  }, []);

  useEffect(() => {
    localStorage.setItem('itemsChosen', JSON.stringify(recipeItems));
  }, [recipeItems]);

  const fetchRecipe = (items, offset, sort, category, deletedItem) => {
    setLoading(true);
    axios
      .post(getRecipeURL, {
        items: items.map(ele => ele.name),
        offset,
        sort,
        category,
      })
      .then(res => {
        if (res.data !== 'end') {
          const recipesList = !deletedItem ? [...recipes] : [];
          setRecipes([...recipesList, ...res.data[0]]);
          setRecipesLength(res.data[1]);
          setRecipesCategories(res.data[2]);
          setOffset(offset + 20);
        } else {
          setEnd(true);
        }
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSortDropdown = e => {
    if (e.value === 'En Az Süre') {
      setSort('total_prep_time');
      setSortText('En Az Süre');
      fetchRecipe(recipeItems, 0, 'total_prep_time', category, true);
    }
    if (e.value === 'En Fazla Süre') {
      setSort('-total_prep_time');
      setSortText('En Fazla Süre');
      fetchRecipe(recipeItems, 0, '-total_prep_time', category, true);
    }
    if (e.value === 'En Az Malzeme') {
      setSort('item_list_len');
      setSortText('En Az Malzeme');
      fetchRecipe(recipeItems, 0, 'item_list_len', category, true);
    }
    if (e.value === 'En Fazla Malzeme') {
      setSort('-item_list_len');
      setSortText('En Fazla Malzeme');
      fetchRecipe(recipeItems, 0, '-item_list_len', category, true);
    }
    setEnd(false);
    setOffset(0);
  };

  const handleCategoryDropdown = e => {
    setCategory(e.value);
    fetchRecipe(recipeItems, 0, sort, e.value, true);
    setEnd(false);
    setOffset(0);
  };

  const handleCloseCategory = () => {
    setCategory('Bütün Kategoriler');
    fetchRecipe(recipeItems, 0, sort, 'Bütün Kategoriler', true);
    setEnd(false);
    setOffset(0);
  };

  const handleItemDeletion = e => {
    setCategory('Bütün Kategoriler');
    setOffset(0);
    setRecipeItems(e);
    setRecipes([]);
    setEnd(false);
    fetchRecipe(e, 0, 'id', 'Bütün Kategoriler', true);
  };

  return (
    <div>
      <Helmet>
        ‍<title>Tarifler | Wasteys</title>‍
        <meta
          name="description"
          content="Seçtiğiniz malzemelerle yapabileceğiniz tarifleri görüntüleyin. Wasteys ile evinizdeki malzemeleri seçip yapabileceğiniz birbirinden güzel tarifler bulabilirsiniz. Beğendiğiniz tarifleri favorilerinize ekleyebilirisiniz."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Tarifler | Wasteys" />
        <meta name="twitter:description" content="Evinizdeki malzemelerle yapabileceğiniz tarifleri görüntüleyin." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Tarifler | Wasteys" />
        <meta property="og:description" content="Evinizdeki malzemelerle yapabileceğiniz tarifleri görüntüleyin." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/result/" />
        <meta property="og:site_name" content="Tarifler | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container fluid className="search-card-container mb-4 mt-3 px-4">
        <BottomMenu chosenItemsLength={recipeItems.length} />
        <Row>
          <Col className="item-list-col-result d-none d-sm-block" sm={6} md={4} lg={3} xl={3}>
            <ItemsChosen
              strong="Seçtiğiniz"
              removeFridgeItem={handleItemDeletion}
              text="Malzemeler"
              items={recipeItems}
              buttonText="Malzeme Seç"
            />
          </Col>
          <Col sm={6} md={8} lg={9} xl={9}>
              <ListItem
                recipe={recipes}
                resultText="Seçtiğiniz malzemelerle yapabileceğiniz"
                category={category}
                handleSortDropdown={handleSortDropdown}
                handleCategoryDropdown={handleCategoryDropdown}
                categories={recipesCategories}
                favoriteSort="id"
                amount={recipesLength}
                sortText={sortText}
                handleCloseCategory={handleCloseCategory}
                end={end}
              />
              {loading &&
            <Col className="d-flex justify-content-center align-items-center mt-3">
              <div className="text-center">
                <h3 className="pb-2" style={{ fontFamily: 'Open Sans' }}>17,000&apos;den fazla tarif araştırılıyor...</h3>
                <img className="mt-2" src={dishes} height="200" width="auto" alt="dish-gif" />
              </div>
            </Col>
          }
          </Col>
          {error && <Error /> } 
          
          {recipes.length === 0 && !loading && !error &&
          <Col className="d-flex justify-content-center align-items-center mt-4">
              <div className="text-center">
                <h3 className="text-center pb-2">Seçtiğiniz malzemelerle yapabileceğiniz tarif bulamadık :(</h3>
                <h2 className="sub-heading text-center">
                  Daha fazla malzeme seçerek 17 bin tarif arasından yapabileceğiniz tarif sayısını artırabilirsiniz.
                </h2>
                <ButtonCTA text="Malzeme Seç" style={{ width: '300px' }} redirect="/search" />
              </div>
            </Col>
          }
          
        </Row>
      </Container>
    </div>
  );
}

export default ResultPage;
