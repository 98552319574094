// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./bird-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".greenCTA:hover{\n    background-color: #1e6b70 !important;\n}\n\n.green-auth-container{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/authenticationGreenCol/AuthGreen.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,yDAAsC;IACtC,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;AAChB","sourcesContent":[".greenCTA:hover{\n    background-color: #1e6b70 !important;\n}\n\n.green-auth-container{\n    background-image: url('./bird-bg.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
