import '../../assets/css/Signup.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import og from '../../assets/img/og.jpg';
import AuthForm from '../../components/authenticationForm/authForm';
import AuthGreen from '../../components/authenticationGreenCol/AuthGreen';
import { authResetStart, authSignup } from '../../store/actions/auth';

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSignupCallback = this.handleSignupCallback.bind(this);
  }

  componentDidMount() {
    if (!this.props.token) {
      this.props.authResetStart();
    }
  }

  handleSignupCallback(email, password1, password2) {
    this.props.signup(email, password1, password2);
  }

  render() {
    const { token, error, confirmation } = this.props;
    if (token) {
      return <Redirect to="/" />;
    }
    if (confirmation) {
      return <Redirect to="/email-confirmation" />;
    }

    return (
      <div className="login-outer-div d-flex align-items-center">
        <Helmet>
          ‍<title>Kaydol | Wasteys</title>‍
          <meta
            name="description"
            content="Kaydolarak kendi dolabınızı oluşturabilir, tarifleri favorilerinize ekleyebilirsiniz.Sana özel oluşturulacak dolabına malzemelerini gir, yapabileceğin yüzlerce yemeği gör. İstediğin zaman malzemeleri ekle veya çıkar."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />
          <meta name="twitter:title" content="Kaydol | Wasteys" />
          <meta name="twitter:description" content="Kaydolarak kendi dolabınızı oluşturabilirsiniz." />
          <meta name="twitter:image" content={og} />
          <meta property="og:title" content="Kaydol | Wasteys" />
          <meta property="og:description" content="Kaydolarak kendi dolabınızı oluşturabilirsiniz." />
          <meta property="og:image" content={og} />
          <meta property="og:url" content="https://wasteys.com/signup/" />
          <meta property="og:site_name" content="Kaydol | Wasteys" />
          <meta property="og:locale" content="tr_TR" />
          <meta property="og:type" content="article" />
        </Helmet>
        <Container className="my-5">
          <Row className="mx-auto login-row ">
            <Col className="p-0" md={5}>
              <AuthGreen
                className="p-3"
                title="Atıksız Bir Dünya Düşünün"
                text="Evsel atıkların %35’i mutfak atıklarıdır. Bu atıkların da çoğu tüketmeyi unuttuğumuz son kullanma tarihi geçmiş gıdalar..."
                redirect="/fridge"
                page="Dolabım"
                source="tomatos"
              />
            </Col>
            <Col className="py-4" md={7}>
              <Container className="">
                <Row className="mx-auto">
                  <Col>
                    <h5 className="mt-4 mb-3">Wasteys&lsquo;e Hoşgeldiniz</h5>
                    {error && [
                      Array.isArray(error) ? (
                        error.map((error, index) => {
                          if (error[0] === "The two password fields didn't match.") {
                            return <p className="auth-form-p mb-1">Girdiğiniz şifreler eşleşmemektedir.</p>;
                          }
                          if (error[0] === 'A user is already registered with this e-mail address.') {
                            return (
                              <p className="auth-form-p mb-1">Bu e-posta adresi ile daha önce kayıt oluşturulmuş.</p>
                            );
                          }
                          if (error[0] === 'This password is too short. It must contain at least 8 characters.') {
                            return <p className="auth-form-p mb-1">Şifrenin en az 8 karakter olması gerekmektedir.</p>;
                          }
                          if (error[0] === 'This password is entirely numeric.') {
                            return <p className="auth-form-p mb-1">Şifre sadece rakamlardan oluşamaz.</p>;
                          }
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <p key={index} className="auth-form-p mb-2">
                              Girdiğiniz bilgileri kontrol edip, lütfen tekrar deneyiniz.
                            </p>
                          );
                        })
                      ) : (
                        <p className="auth-form-p mb-3"> Lütfen daha sonra tekrar deneyiniz. </p>
                      ),
                    ]}
                  </Col>
                </Row>
              </Container>

              <AuthForm
                SignupCallback={this.handleSignupCallback}
                title="Wasteys'e Hoşgeldiniz"
                email="email"
                password1="password1"
                password2="password2"
                buttonText="Üye Ol"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  token: state.auth.token,
  confirmation: state.auth.confirmation,
});

const mapDispatchToProps = dispatch => ({
  authResetStart: () => dispatch(authResetStart()),
  signup: (email, password1, password2) => dispatch(authSignup(email, password1, password2)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
