import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import authReducer from "./store/reducers/auth";
import errorsReducer from "./store/reducers/errors";
import itemsReducer from "./store/reducers/items";
import itemsFridgeReducer from "./store/reducers/itemsFridge";
import favoriteReducer from "./store/reducers/favorite";
import itemsChosenReducer from "./store/reducers/itemsChosen";
import recipeReducer from "./store/reducers/recipe";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-190322622-7');
ReactGA.pageview(window.location.pathname + window.location.search);

require('dotenv').config()

const middleware = [thunk];

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorsReducer,
  items: itemsReducer,
  itemsFridge: itemsFridgeReducer,
  favorite: favoriteReducer,
  itemsChosen: itemsChosenReducer,
  recipe: recipeReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
