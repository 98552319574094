import '../assets/css/SearchPage.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import GoogleAd from '../components/GoogleAd';

import G from '../assets/img/bread.png';
import D from '../assets/img/dairy.png';
import F from '../assets/img/fruit.png';
import L from '../assets/img/legume.png';
import M from '../assets/img/meat.png';
import og from '../assets/img/og.jpg';
import sort from '../assets/img/sort.png';
import S from '../assets/img/spice.png';
import BottomMenu from '../components/bottomMenu/BottomMenu';
import ButtonCTA from '../components/buttonCTA/ButtonCTA';
import { getItemURL } from '../constants';
import Error from './Error';

const _ = require('lodash');

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const itemsInitialSkeleton = [
  { categoryID: 'G', categoryName: 'Tahıl', categoryIcon: G, items: [] },
  { categoryID: 'D', categoryName: 'Süt ve Süt Ürünleri', categoryIcon: D, items: [] },
  { categoryID: 'M', categoryName: 'Et ve Et Ürünleri', categoryIcon: M, items: [] },
  { categoryID: 'L', categoryName: 'Bakliyat', categoryIcon: L, items: [] },
  { categoryID: 'F', categoryName: 'Meyve & Sebze ve Kuruyemiş', categoryIcon: F, items: [] },
  { categoryID: 'S', categoryName: 'Baharatlar ve Diğer Malzemeler', categoryIcon: S, items: [] },
]

function SearchPage() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosenItems, setChosenItems] = useState(JSON.parse(localStorage.getItem('itemsChosen')).some(value => { return typeof value == "object" }) ? JSON.parse(localStorage.getItem('itemsChosen')) : []);
  const [items, setItems] = useState(itemsInitialSkeleton);
  const [collapse, setCollapse] = useState({
    G: false,
    D: false,
    M: false,
    L: false,
    F: false,
    S: false,
  });
  const [filter, setFilter] = useState({ G: '', D: '', M: '', L: '', F: '', S: '' });
  const [itemsToBeSorted, setItemsToBeSorted] = useState({
    G: false,
    D: false,
    M: false,
    L: false,
    F: false,
    S: false,
  });
  const isAuthenticated = localStorage.getItem('token');

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    localStorage.setItem('itemsChosen', JSON.stringify(chosenItems));
  }, [chosenItems]);

  const fetchItems = () => {
    const itemsSkeleton = [
      { categoryID: 'G', categoryName: 'Tahıl', categoryIcon: G, items: [] },
      { categoryID: 'D', categoryName: 'Süt ve Süt Ürünleri', categoryIcon: D, items: [] },
      { categoryID: 'M', categoryName: 'Et ve Et Ürünleri', categoryIcon: M, items: [] },
      { categoryID: 'L', categoryName: 'Bakliyat', categoryIcon: L, items: [] },
      { categoryID: 'F', categoryName: 'Meyve & Sebze ve Kuruyemiş', categoryIcon: F, items: [] },
      { categoryID: 'S', categoryName: 'Baharatlar ve Diğer Malzemeler', categoryIcon: S, items: [] },
    ]
    const firstTime = [];
    setLoading(true);
    axios
      .get(getItemURL)
      .then(res => {
        res.data.forEach(item => {          
          itemsSkeleton.find(category => category.categoryID === item.category).items.push(item.name);
          if (item.first_time) {
            const itemObj = {};
            itemObj.name = item.name;
            itemObj.category = item.category;
            firstTime.push(itemObj);
          }
        });
        setItems(itemsSkeleton);
        if (localStorage.getItem('itemListModified') !== 'true') {
          setChosenItems(firstTime);
        }
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = e => {
    addRemoveItem(e.target.name);
  };

  const handleCollapse = categoryID => {
    const updatedCollapse = { ...collapse };
    updatedCollapse[categoryID] = !updatedCollapse[categoryID];
    setCollapse(updatedCollapse);
  };

  const addRemoveItem = item => {
    const itemName = item.slice(0, -1);
    const itemCategory = item.slice(-1);
    localStorage.setItem('itemListModified', true);

    if (item === 'deselect') {
      setChosenItems([]);
    } else {
      const items = [...chosenItems];
      if (items.map(item => item.name).includes(itemName)) {
        _.remove(items, itema => itema.name === itemName);
        setChosenItems(items);
      } else {
        const itemObj = {};
        itemObj.name = itemName;
        itemObj.category = itemCategory;
        items.push(itemObj);
        setChosenItems(items);
      }
    }
  };

  const handleDeselectAll = ID => {
    const index = items.findIndex(obj => obj.categoryID === ID);
    const itemsLeft = chosenItems.filter(el => !items[index].items.includes(el.name));
    setChosenItems(itemsLeft);
  };

  const handleFilter = event => {
    const updatedFilter = { ...filter };
    updatedFilter[event.target.name] = event.target.value;
    setFilter(updatedFilter);
  };

  const handleSort = categoryID => {
    const updatedItemsToBeSorted = { ...itemsToBeSorted };
    updatedItemsToBeSorted[categoryID] = true;
    setItemsToBeSorted(updatedItemsToBeSorted);
  };

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div>
      <Helmet>
        ‍<title>Malzeme Seçin | Wasteys</title>‍
        <meta
          name="description"
          content="400 malzeme arasından evinizdeki malzemeleri seçin, Yemek Bul butonuna tıklayın ve 17 bin tarif arasından yapabileceğiniz tarifleri görün. Üye olarak seçtiğiniz malzemeleri dolabınıza ekleyin."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Malzeme Seçin | Wasteys" />
        <meta name="twitter:description" content="Evinizdeki malzemeleri seçin ve yapabileceğiniz tarifleri görün." />
        <meta name="twitter:image" content={og} />
        <meta property="og:title" content="Malzeme Seçin | Wasteys" />
        <meta property="og:description" content="Evinizdeki malzemeleri seçin ve yapabileceğiniz tarifleri görün." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/search/" />
        <meta property="og:site_name" content="Malzeme Seçin | Wasteys" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container id="cont">
        <Row>
          <Col className="text-center mt-4">
            <h4 className="title mb-2 mobile-title">Malzeme Seçin</h4>

            <p className="sub-heading my-2">
              {!localStorage.getItem('itemListModified')
                ? 'Deneyiminizin kusursuz olması için evinizde bulunması muhtemel malzemeleri sizin için seçtik, arzunuza göre daha fazla malzeme ekleyip, çıkartabilirsiniz. Üye olup, malzemeleri dolabınıza ekleyebilirsiniz.'
                : 'Sahip olduğunuz malzemeleri seçin ve hangi yemekleri yapabileceğinizi görün, üye olup malzemeleri dolabınıza ekleyin.'}
            </p>
            <BottomMenu chosenItemsLength={chosenItems.length} chosenItems={chosenItems} />
          </Col>
        </Row>
      </Container>
      {error ? (
        <Error />
      ) : (
        <Container fluid className="search-card-container mt-2">
          
            <Row className="d-flex justify-content-center">
              {items?.map((item, ind) => {
                const sortedItems = item.items;
                if (itemsToBeSorted[item.categoryID]) {
                  sortedItems.sort(new Intl.Collator('tr').compare);
                }
                return (
                  <React.Fragment key={item.categoryID}>
                    <Col className="d-block d-sm-none col-12 mb-4">
                      <Card className="rounded-0">
                        <Card.Header
                          className="item-card-header-mobile rounded-0 d-flex align-items-center"
                          as={Card.Header}
                          onClick={() => handleCollapse(item.categoryID)}
                        >
                          <img src={item.categoryIcon} alt={item.categoryIcon} className="mr-3" height="30px" />
                          {item.categoryName}
                          {!collapse[item.categoryID] ? (
                            <ChevronDown className="ml-auto" />
                          ) : (
                            <ChevronUp className="ml-auto" />
                          )}
                        </Card.Header>
                        <Card.Body>
                          <div>
                            <input
                              id={`${item.categoryID} ${item.categoryName}`}
                              key={item.categoryID}
                              placeholder="&#x1F50E;&#xFE0E; Malzeme Ara "
                              name={item.categoryID}
                              type="text"
                              className="search-input"
                              autoComplete="off"
                              value={filter[item.categoryID]}
                              onChange={handleFilter}
                            />
                            <button
                              className="m-0 p-0 float-right"
                              onClick={() => handleSort(item.categoryID)}
                              style={{ border: 'none', backgroundColor: 'white' }}
                              type="button"
                            >
                              <img className="" src={sort} alt="sort" height="16px" />
                            </button>
                          </div>
                          {loading ? (
                            <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '180px' }}>
                              <Spinner animation="border" variant="info" />
                            </Row>
                          ) : (
                          <Row className="mt-3 px-2">
                            {sortedItems
                              .slice(0, !collapse[item.categoryID] ? 12 : sortedItems.length)
                              .filter(f => f.startsWith(filter[item.categoryID]))
                              .map((ing, index) => (
                                <Col className="px-1 col-auto mb-2" key={ing}>
                                  <label
                                    htmlFor={ing}
                                    id="input"
                                    className={
                                      chosenItems.map(item => item.name).includes(ing)
                                        ? 'checkbox-label'
                                        : 'non-checked-label'
                                    }
                                    key={ing}
                                  >
                                    <input
                                      type="checkbox"
                                      id={ing}
                                      name={ing + item.categoryID}
                                      checked={chosenItems.map(item => item.name).includes(ing)}
                                      onChange={handleCheckboxChange}
                                      className="form-check-input"
                                    />
                                    {capitalizeFirstLetter(ing)}
                                  </label>
                                </Col>
                              ))}
                          </Row>
                          )}
                          <button
                            className="mobile-item-card-button mt-2"
                            type="button"
                            onClick={() => handleCollapse(item.categoryID)}
                          >
                            Daha {!collapse[item.categoryID] ? 'Fazla' : 'Az'} Malzeme Göster{' '}
                            {!collapse[item.categoryID] ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
                          </button>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      sm={5}
                      md={4}
                      xl={2}
                      className="justify-content-center text-center ing-col search-ing-col mx-4 mx-sm-0 my-3 px-4 px-sm-2 py-4 py-sm-0 d-none d-sm-block"
                      key={item.categoryID}
                    >
                      <div className="div-img" id={item.categoryID}>
                        <h1 className="ing-title">{item.categoryName}</h1>
                      </div>
                      <div className="div-list" style={{ position: 'relative' }}>
                        <div className="d-flex justify-content-center align-items-center ing-title-div">
                          <h1 className="ing-list-title m-0">{item.categoryName}</h1>
                        </div>
                        <input
                          id={`${item.categoryID} ${item.categoryName}`}
                          key={item.categoryID}
                          placeholder="&#x1F50E;&#xFE0E; Malzeme Ara "
                          name={item.categoryID}
                          type="text"
                          className="search-input mt-2"
                          autoComplete="off"
                          value={filter[item.categoryID]}
                          onChange={handleFilter}
                        />
                        <button
                          className="m-0 p-0"
                          onClick={() => handleSort(item.categoryID)}
                          style={{ border: 'none', backgroundColor: 'white' }}
                          type="button"
                        >
                          <img className="" src={sort} alt="sort" height="16px" />
                        </button>
                        <Scrollbars id="scrollBar" autoHide autoHideDuration={1000}>
                          {sortedItems
                            .filter(f => f.startsWith(filter[item.categoryID]))
                            .map((ing, index) => (
                              <div
                                className="scrollbar-div"
                                key={ing}
                                style={{
                                  textAlign: 'left',
                                  marginRight: '0.5rem',
                                  marginLeft: '0.5rem',
                                  marginTop: '0.25rem',
                                }}
                              >
                                <label
                                  htmlFor={ing}
                                  id="input"
                                  className={
                                    chosenItems.map(item => item.name).includes(ing)
                                      ? 'checkbox-label w-100'
                                      : 'non-checked-label w-100'
                                  }
                                  key={ing}
                                >
                                  <input
                                    type="checkbox"
                                    id={ing}
                                    name={ing + item.categoryID}
                                    checked={chosenItems.map(item => item.name).includes(ing)}
                                    onChange={handleCheckboxChange}
                                    className="form-check-input"
                                  />
                                  {capitalizeFirstLetter(ing)}
                                </label>
                              </div>
                            ))}
                        </Scrollbars>
                        <button
                          className="remove-button"
                          onClick={() => handleDeselectAll(item.categoryID)}
                          type="button"
                        >
                          Hepsini Sil
                        </button>
                      </div>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
        </Container>
      )}
      <div className="hp-3-spacer" />
      <div className='homepage-ad-div mb-5'>
        <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="3955260487"/>    
        <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="2835704214"/>    
      </div>
      <Container fluid className="yellow-fluid-container">
        <Row className=" py-5">
          <Col className="d-flex align-items-center justify-content-center">
            <div className="w-100 text-center">
              <h2>Malzemelerini seç, dolabına ekle</h2>
              <p>
                Üye olarak kendine özel dolap oluşturabilirsin, malzemelerini güncelleyebilir, yapabileceğin yemekleri
                görebilirsin.
              </p>
              {isAuthenticated ? (
                <ButtonCTA text="Dolabım" redirect="/fridge" />
              ) : (
                <ButtonCTA text="Üye Ol" redirect="/signup" />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SearchPage;
