import { ITEMS_FRIDGE_START, ITEMS_FRIDGE_SUCCESS, ITEMS_FRIDGE_FAIL, ITEMS_FRIDGE_RESET } from "../actions/actionTypes";
import { updateObject } from "../utility";


const initialState = {
  itemsFridge: [],
  error: null,
  loading: false
};

const itemsFridgeStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const itemsFridgeSuccess = (state, action) => {
  return updateObject(state, {
    itemsFridge: action.data,
    error: null,
    loading: false
  });
};

const itemsFridgeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const itemsFridgeReset = (state, action) => {
  return updateObject(state, {
    itemsFridge: []
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_FRIDGE_START:
      return itemsFridgeStart(state, action);
    case ITEMS_FRIDGE_SUCCESS:
      return itemsFridgeSuccess(state, action);
    case ITEMS_FRIDGE_FAIL:
      return itemsFridgeFail(state, action);
    case ITEMS_FRIDGE_RESET:
      return itemsFridgeReset(state, action);
    default:
      return state;
  }
};

export default reducer;
