import './ScrollToTop.css';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import scrolltotop from './scrolltotop.png';

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible &&
        (location.pathname.includes('result') ||
          location.pathname.includes('fridge') ||
          location.pathname.includes('favorite')) && (
          <div style={{ cursor: 'pointer', animation: `fadeIn 1s` }} onClick={scrollToTop}>
            <img src={scrolltotop} height="40" alt="Go to top" />
          </div>
        )}
    </div>
  );
}
