import React, { Component } from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';
import choose from '../assets/img/choose.gif'; 
import fridge from '../assets/img/fridge.gif'; 
import GreenCTA from "./greenCTA/GreenCTA";


class GrayBanner extends Component {
render() {
    let iconObj = {
        "choose" : choose,
        "fridge" : fridge
    }
    return (    
        <Row className='bg-dark mb-4'>
            <Col className="hv-center mx-4 py-4">
                <div>
                    <h2 className='text-white'>{this.props.title}</h2>
                    <p className='text-white font-weight-light'>{this.props.text}</p>
                    <GreenCTA text={this.props.page} redirect={this.props.redirect} />
                </div>
            </Col>
            <Col className="py-3 text-center">                
                <img height={250} src={iconObj[this.props.source]} alt={this.props.source} />                 
            </Col>
        </Row>  
        
    );
  }
}

export default GrayBanner;