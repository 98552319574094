import { FAVORITE_START, FAVORITE_SUCCESS, FAVORITE_FAIL, FAVORITE_RESET } from "../actions/actionTypes";
import { updateObject } from "../utility";


const initialState = {
  favorite: [],
  error: null,
  loading: false,
  categories: [],
  length: 0,
};

const favoriteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const favoriteSuccess = (state, action) => {
  return updateObject(state, {
    favorite: action.data[0],
    error: null,
    loading: false,
    categories: action.data[1],
    length: action.data[2],
  });
};

const favoriteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    favorite: [],
    categories: [],
    length: [],
  });
};


const favoriteReset = (state, action) => {
  return updateObject(state, {
    favorite: [],
    categories: [],
    length: [],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITE_START:
      return favoriteStart(state, action);
    case FAVORITE_SUCCESS:
      return favoriteSuccess(state, action);
    case FAVORITE_FAIL:
      return favoriteFail(state, action);
    case FAVORITE_RESET:
      return favoriteReset(state, action);
    default:
      return state;
  }
};

export default reducer;
