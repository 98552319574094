import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import { facebookAuth } from '../../store/actions/auth';
import { connect } from "react-redux";
import "./FacebookSocialAuth.css"
import facebookIcon from "./facebook.svg"

class FacebookSocialAuth extends Component {
  state = {
    accessToken: ""
  }

  responseFacebook = (response) => {
    this.setState({ accessToken: response.accessToken})
    this.props.facebookResponse(this.state.accessToken)
  }

  render() {
    return (  
        <FacebookLogin
        appId="548864716408142"
        autoLoad={false}
        callback={this.responseFacebook}
        disableMobileRedirect={true}
        fields="name,email"
        cssClass="my-facebook-button-class d-flex align-items-center py-auto"
        icon={<img src={facebookIcon} width="28" className="mr-2" alt="facebook"/>}
        textButton="Facebook ile devam edin"
        /> 
    );
  }
}


const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    errors: state.errors,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    facebookResponse: (accessToken) => dispatch(facebookAuth(accessToken))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookSocialAuth);