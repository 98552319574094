import '../../assets/css/Login.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import og from '../../assets/img/og.jpg';
import AuthForm from '../../components/authenticationForm/authForm';
import AuthGreen from '../../components/authenticationGreenCol/AuthGreen';
import { authLogin, authResetStart } from '../../store/actions/auth';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginCallback = this.handleLoginCallback.bind(this);
  }

  componentDidMount() {
    if (!this.props.token) {
      this.props.authResetStart();
    }
  }

  handleLoginCallback(email, password) {
    this.props.login(email, password);
  }

  render() {
    const { token, error } = this.props;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <div className="login-outer-div d-flex align-items-center">
        <Helmet>
          ‍<title>Giriş | Wasteys</title>‍
          <meta
            name="description"
            content="Giriş yaparak kendi dolabınızı oluşturabilir, tarifleri favorilerinize ekleyebilirsiniz. Sana özel oluşturulacak dolabına malzemelerini gir, yapabileceğin yüzlerce yemeği gör. İstediğin zaman malzemeleri ekle veya çıkar."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />
          <meta name="twitter:title" content="Giriş | Wasteys" />
          <meta name="twitter:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta name="twitter:image" content={og} />
          <meta property="og:title" content="Giriş | Wasteys" />
          <meta property="og:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta property="og:image" content={og} />
          <meta property="og:url" content="https://wasteys.com/login/" />
          <meta property="og:site_name" content="Giriş | Wasteys" />
          <meta property="og:locale" content="tr_TR" />
          <meta property="og:type" content="article" />
        </Helmet>
        <Container className="my-5">
          <Row className="mx-auto login-row ">
            <Col className="p-0" md={5}>
              <AuthGreen
                className="p-3"
                title="Atıksız Bir Dünya Düşünün"
                text="Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz..."
                redirect="/fridge"
                page="Dolabım"
                source="tomatos"
              />
            </Col>
            <Col className="py-md-5 py-4" md={7}>
              <Container className="">
                <Row className="mx-auto">
                  <Col>
                    <h5 className="mt-1 mb-3">Giriş Yapın</h5>
                    {error && [
                      Array.isArray(error) ? (
                        error.map((error, index) => {
                          if (error === 'E-mail is not verified.') {
                            return (
                              <p key="1" className="auth-form-p mb-3">
                                Lütfen e-postanızı onaylayın.
                              </p>
                            );
                          }
                          if (error[0] === 'Unable to log in with provided credentials.') {
                            return (
                              <p key="2" className="auth-form-p mb-3">
                                Verdiğiniz bilgiler ile giriş sağlanamadı, lütfen bilgileri kontrol edip tekrar
                                deneyiniz.
                              </p>
                            );
                          }
                          if (error === 'Incorrect input. access_token or code is required.') {
                            return (
                              <p key="3" className="auth-form-p mb-3">
                                Geçersiz giriş, lütfen tekrar deneyiniz.
                              </p>
                            );
                          }
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <p key={index} className="auth-form-p mb-3">
                              Bir hata oluştu, lütfen tekrar deneyiniz.
                            </p>
                          );
                        })
                      ) : (
                        <p className="auth-form-p mb-3">Lütfen daha sonra tekrar deneyiniz.</p>
                      ),
                    ]}
                  </Col>
                </Row>
              </Container>

              <AuthForm
                LoginCallback={this.handleLoginCallback}
                title="Giriş Yapın"
                email="email"
                password="password"
                buttonText="Giriş Yap"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  authResetStart: () => dispatch(authResetStart()),
  login: (email, password) => dispatch(authLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
