import './Navbar.css';

import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';

import close from '../../assets/img/close.svg';
import logo from '../../assets/img/logo.png';
import whiteLogo from '../../assets/img/white-logo.png';
import { logout } from '../../store/actions/auth';
import ButtonCTA from '../buttonCTA/ButtonCTA';

function NavigationBar(props) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState(location);

  useEffect(() => {
    if (path !== location) {
      setOpen(false);
      setPath(location);
    }
  });

  const navbarToggle = () => {
    setOpen(!open);
  };
  return (
    <Container fluid className="top-navbar-container px-0">
      <Navbar expanded={open} className="" expand="xl">
        <Link to="/" className="d-flex align-items-center">
          <Navbar.Brand>
            <img className="normal-logo" src={logo} height="48" alt="Logo" />
            <img className="white-logo" src={whiteLogo} height="48" alt="Logo" />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" aria-expanded={open} onClick={navbarToggle}>
          <span className="navbar-toggler-icon" />
          <img className="close-icon" src={close} height="22" alt="logo" />
        </Navbar.Toggle>

        <Navbar.Collapse>
          <Nav className="d-flex justify-content-center text-center align-items-center">
            <Link className="nav-link" to="/why">
              Neden
            </Link>
            <Link className="nav-link" to="/how">
              Nasıl
            </Link>
            <Link className="nav-link" to="/search">
              Yemek Bul
            </Link>
          </Nav>
          {props.isAuthenticated ? (
            <Nav className="ml-auto">
              <ButtonCTA
                text="Favorilerim"
                redirect="/favorite"
                className="outline mr-md-0 mr-lg-3 navbar-button-mobile-outline mobile-inline-buttons"
                style={{ minWidth: '120px' }}
              />
              <ButtonCTA
                text="Dolabım"
                redirect="/fridge"
                style={{ minWidth: '120px' }}
                className="navbar-button-mobile green mobile-inline-buttons d-none d-sm-block"
              />
              <ButtonCTA
                text="Dolabım"
                redirect="/fridgeitems"
                style={{ minWidth: '120px' }}
                className="navbar-button-mobile green mobile-inline-buttons d-block d-sm-none"
              />
              <Link
                className="nav-link ml-3 text-center"
                to="/"
                onClick={() => {
                  props.logout();
                }}
              >
                Çıkış
              </Link>
            </Nav>
          ) : (
            <Nav className="ml-auto">
              <ButtonCTA
                text="Giriş"
                redirect="/login"
                className="outline mr-md-0 mr-lg-3 navbar-button-mobile-outline mobile-inline-buttons"
                style={{ minWidth: '120px' }}
              />
              <ButtonCTA
                text="Üye Ol"
                redirect="/signup"
                style={{ minWidth: '120px' }}
                className="navbar-button-mobile green mobile-inline-buttons"
              />
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.token !== null,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar));
