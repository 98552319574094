import './IsAuthenticatedModal.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import ButtonCTA from '../buttonCTA/ButtonCTA';

function IsAuthenticatedModal(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    props.handleClose();
    setShow(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className=" pb-0 px-4 pt-4 border-0">
        <Modal.Title style={{ fontSize: '1.25rem' }}>{props.modalMessage} giriş yapmanız gerekmektedir.</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 pt-0">
        Üye olarak tarifleri favorilerinize ekleyebilir ve malzemeleri dolabınıza ekleyebilirsiniz.
        <Row className="mt-4">
          <Col xs={6} className="pr-2 text-sm-right">
            <Button onClick={handleClose} className="inline-buttons outline CTA">
              Geri Dön
            </Button>
          </Col>
          <Col xs={6} className="pl-2 text-sm-left">
            <ButtonCTA
              text="Giriş Yap"
              redirect="/login"
              className="inline-buttons green"
              linkClassName="mobile-inline-buttons"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default IsAuthenticatedModal;
