/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './authForm.css';

import React from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import FacebookSocialAuth from '../facebookLogin/FacebookSocialAuth';
import GoogleSocialAuth from '../googleLogin/GoogleSocialAuth';
import slash from './eye-slash-solid.svg';
import eye from './eye-solid.svg';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      password1: '',
      password2: '',
      showPassword: false,
      showPassword2: false,
    };

    this.handleTogglePassword = this.handleTogglePassword.bind(this);
    this.handleTogglePassword2 = this.handleTogglePassword2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTogglePassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleTogglePassword2() {
    const { showPassword2 } = this.state;
    this.setState({ showPassword2: !showPassword2 });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.password) {
      const { email, password } = this.state;
      this.props.LoginCallback(email, password);
    } else if (this.state.password1) {
      const { email, password1, password2 } = this.state;
      this.props.SignupCallback(email, password1, password2);
    }
  }

  render() {
    const { loading, token, error } = this.props;
    const { showPassword, showPassword2 } = this.state;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <Container className="mb-2 auth-form-container">
        <Row className="mx-auto">
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="E-Posta Adresiniz"
                  required
                  onChange={this.handleChange}
                  name="email"
                />
              </Form.Group>
              {this.props.password && (
                <Form.Group controlId="formBasicPassword" style={{ position: 'relative' }}>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Şifreniz"
                    required
                    onChange={this.handleChange}
                    name="password"
                  />
                  <img
                    className="eye-icon"
                    src={showPassword ? slash : eye}
                    alt="Logo"
                    onClick={this.handleTogglePassword}
                  />
                </Form.Group>
              )}
              {this.props.password1 && (
                <Form.Group controlId="formBasicPassword1" style={{ position: 'relative' }}>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Şifreniz"
                    required
                    onChange={this.handleChange}
                    name="password1"
                  />
                  <img
                    className="eye-icon"
                    src={showPassword ? slash : eye}
                    alt="Logo"
                    onClick={this.handleTogglePassword}
                  />
                </Form.Group>
              )}
              {this.props.password2 && (
                <Form.Group controlId="formBasicPassword2" style={{ position: 'relative' }}>
                  <Form.Control
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder="Şifreniz Tekrar"
                    required
                    onChange={this.handleChange}
                    name="password2"
                  />
                  <img
                    className="eye-icon"
                    src={showPassword2 ? slash : eye}
                    alt="Logo"
                    onClick={this.handleTogglePassword2}
                  />
                </Form.Group>
              )}
              {!this.props.password2 && (
                <Link to="/reset-password" className="forgot-password-button">
                  Şifremi unuttum
                </Link>
              )}
              {loading ? (
                <div className="d-flex justify-content-center mt-4">
                  <Spinner animation="border" role="status" style={{ color: '#319DA4' }}>
                    <span className="sr-only ">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Button className="auth-button mt-3" type="submit" variant="primary" id="giris-yap">
                  {this.props.buttonText}
                </Button>
              )}
              <p className="text-center mt-2" style={{ fontSize: '12px', color: 'gray' }}>
                VEYA
              </p>
              {error !== 'Cookies are not enabled in current environment.' && (
                <GoogleSocialAuth text="Google ile devam edin" />
              )}
              <FacebookSocialAuth />
            </Form>
            {this.props.password1 && (
              <div>
                <p className="pt-3" style={{ fontSize: '10px', color: 'gray' }}>
                  Kişisel verileriniz, Aydınlatma Metni kapsamında işlenmektedir. “Üye ol” veya “Google ile devam edin”
                  butonlarından birine basarak
                  <a href="/uyelik-sozlesmesi" className="kvkk-tag">
                    {' '}
                    Üyelik Sözleşmesi’ni
                  </a>
                  ,
                  <a href="/kvk" className="kvkk-tag">
                    {' '}
                    Rıza Metni’ni
                  </a>
                  ,
                  <a href="/cerez-politikasi" className="kvkk-tag">
                    {' '}
                    Çerez Politikası’nı
                  </a>
                  , okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
                </p>
                <p className="mb-0" style={{ fontSize: '14px', color: 'rgb(70, 70, 70)' }}>
                  Zaten üye misiniz?{' '}
                  <Link to="/login" style={{ color: 'rgb(70, 70, 70)', textDecoration: 'none' }}>
                    {' '}
                    <strong>Giriş yap</strong>{' '}
                  </Link>
                </p>
              </div>
            )}
            {this.props.password && (
              <div>
                <p className="pt-4 mb-0" style={{ fontSize: '14px', color: 'rgb(70, 70, 70)' }}>
                  Üye değil misiniz?{' '}
                  <Link to="/signup" style={{ color: 'rgb(70, 70, 70)', textDecoration: 'none' }}>
                    {' '}
                    <strong>Üye ol</strong>{' '}
                  </Link>
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

export default connect(mapStateToProps)(AuthForm);
