let localhost_env
let localhost_admin

if (process.env.REACT_APP_DEV_ENV === 'true') {
    localhost_env = 'http://127.0.0.1:8000/api'
    localhost_admin = 'http://127.0.0.1:8000'
} else {
    localhost_env = 'https://api.wasteys.com'
    localhost_admin = 'https://admin.wasteys.com'
}


export const localhost = localhost_env
export const localhostAdmin = localhost_admin

// const apiURL = '/api'

export const endpoint = `${localhost}`
// export const endpoint = `${localhost}${apiURL}`

export const getRecipeURL = `${endpoint}/get-recipe/`
// export const getRecipeCategoryURL = `${endpoint}/get-recipe-category/`
export const getFavoriteURL = `${endpoint}/favorite/`
export const favoriteToggleURL = `${endpoint}/toggle-favorite/`
export const getFridgeURL = `${endpoint}/fridge/`
export const getItemURL = `${endpoint}/get-item/`
export const addToFridgeURL = `${endpoint}/add-to-fridge/`
export const itemDeleteURL = `${endpoint}/fridge/delete/`;