import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Confirmation from './containers/auth/Confirmation';
import ConfirmResetPassword from './containers/auth/ConfirmResetPassword';
import Login from './containers/auth/Login';
import ResetPassword from './containers/auth/ResetPassword';
import Signup from './containers/auth/Signup';
import ChosenPage from './containers/ChosenPage';
import Favorite from './containers/Favorite';
import Fridge from './containers/Fridge';
import FridgeItems from './containers/FridgeItems';
import Homepage from './containers/Homepage';
import How from './containers/how/How';
import NoMatchPage from './containers/noMatch/NoMatchPage';
import ResultPage from './containers/ResultPage';
import SearchPage from './containers/SearchPage';
import Why from './containers/why/Why';
import About from './containers/about/About';
import FAQ from './containers/faq/FAQ';
import KK from './containers/kk/KK';
import KVK from './containers/kvk/KVKa';
import SignupContract from './containers/signupContract/SignupContract';
import PrivacyPolicy from './containers/privacyPolicy/PrivacyPolicy';
import CookiePolicy from './containers/cookiePolicy/CookiePolicy';
import Hoc from './hoc/hoc';

function BaseRouter() {
  return (
    <Hoc>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/hakkimizda" component={About} />
        <Route exact path="/sss" component={FAQ} />
        <Route exact path="/gizlilik-politikasi" component={PrivacyPolicy} />
        <Route exact path="/cerez-politikasi" component={CookiePolicy} />
        <Route exact path="/kullanim-kosullari" component={KK} />
        <Route exact path="/uyelik-sozlesmesi" component={SignupContract} />
        <Route exact path="/kvk" component={KVK} />
        <Route exact path="/why" component={Why} />
        <Route exact path="/how" component={How} />
        <Route exact path="/favorite" component={Favorite} />
        <Route exact path="/fridge" component={Fridge} />
        <Route exact path="/fridgeitems" component={FridgeItems} />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/chosen" component={ChosenPage} />
        <Route exact path="/result" component={ResultPage} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/email-confirmation" component={Confirmation} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/rest-auth/password/reset/confirm/:uid/:token/" component={ConfirmResetPassword} />
        <Route component={NoMatchPage} />
      </Switch>
    </Hoc>
  );
}

export default BaseRouter;
