import React from "react";
import "./how.css"
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class How extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Nasıl | Wasteys</title>‍
                    <meta name="description" content="Elinizdeki malzemeleri çöpe atmadığınız bir dünya düşünün. Evinizdeki malzemeleri malzeme listesinden seçin ve yapabileceğiniz yemekleri bulun. Üye olun ve seçtiğiniz malzemeler dolabınıza eklensin." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Nasıl | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri çöpe atmadığınız bir dünya düşünün." />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Nasıl | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri çöpe atmadığınız bir dünya düşünün." />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/how/" />
                    <meta property="og:site_name" content="Nasıl | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="my-5">
                        <Row>
                            <Col sm="12" md="6" className="how-col d-flex align-items-center justify-content-center py-5">
                                <div className="mx-4">
                                    <h1 className="title" style={{ fontSize: "2rem", color: "white", letterSpacing:"0.08rem"}}>
                                    Çünkü,
                                    </h1>
                                    <p className="sub-heading" style={{ color: "white", fontWeight:"300" }}>
                                    “...Dünya’da her sene 9 milyon insan açlık sebebiyle hayatını kaybediyor ve bu insanların hayatta kalmaları için gereken gıda miktarı sadece 5 milyon ton. Sadece Türkiye’deki atık miktarının %20’si bile bu ölümleri bitirmeye yeter.
                                    <br></br><br></br>Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz. Bu rakam dünya genelinde 1.3 milyar ton gıda.”
                                    </p>
                                </div>                            
                            </Col>
                            <Col sm="12" md="6" className="d-flex justify-content-center align-items-center mt-5">
                                <div className="text-center">
                                    <h2 className="text-center">
                                    Malzeme Seçin
                                    </h2>
                                    <p className="sub-heading text-center">
                                    Evinizdeki malzemeleri malzeme listesinden seçin ve yapabileceğiniz
                                    yemekleri bulun. Üye olun ve seçtiğiniz malzemeler dolabınıza eklensin.
                                    </p>
                                    <GreenCTA text="Hemen Seç" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}
export default How;