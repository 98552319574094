import './ItemsChosen.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';

const _ = require('lodash');

function ItemsChosen(props) {
  const [filter, setFilter] = useState('');
  const [itemsToBeRemoved, setItemsToBeRemoved] = useState([]);

  const handleFilter = event => {
    setFilter(event.target.value);
  };

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  const handleCheckboxChange = e => {
    addRemoveItem(e.target.name);
  };

  const addRemoveItem = selectedItem => {
    const items = [...itemsToBeRemoved];
    if (items.includes(selectedItem)) {
      _.remove(items, ele => ele === selectedItem);
    } else {
      items.push(selectedItem);
    }
    setItemsToBeRemoved(items);
  };

  const handleDeleteItems = () => {
    const itemsLeft = props.items.filter(el => !itemsToBeRemoved.includes(el.name));
    props.removeFridgeItem(itemsLeft);
  };

  return (
    <Row className="px-2 mb-3 item-chosen-row">
      <Col sm={12} className=" mb-3 px-0">
        <Link to="/search">
          <Button className="back-to-search-button">
            {' '}
            <span
              style={{
                transform: 'rotate(180deg)',
                display: 'inline-block',
                marginRight: '0.5rem',
                paddingBottom: '0.09rem',
              }}
            >
              &#x2794;
            </span>
            {props.buttonText}
          </Button>
        </Link>
      </Col>
      <Col className="item-list-col">
        <div className="ing-col result-page-list-item-col mx-0">
          <div className="d-flex align-items-center mt-3">
            <h1 className="items-result-page m-0 mb-1 ml-3">
              {' '}
              <strong>{props.strong}</strong> {props.text}
            </h1>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <input
              id="filter"
              key="filter"
              placeholder="&#x1F50E;&#xFE0E; Malzeme Ara"
              name="filter"
              type="text"
              className="search-input mt-2"
              autoComplete="off"
              value={filter}
              onChange={handleFilter}
            />
          </div>
          <Scrollbars id="scrollBar-result" autoHide autoHideDuration={1000}>
            <div style={{ textAlign: 'left', marginRight: '0.5rem', marginLeft: '0.5rem', marginTop: '0.25rem' }}>
              {props.items
                .map(ele => ele.name)
                .filter(f => f.startsWith(filter))
                .sort(new Intl.Collator('tr').compare)
                .map(ingre => (
                  <label
                    htmlFor={ingre}
                    id="input"
                    className={itemsToBeRemoved.includes(ingre) ? 'checkbox-label w-100' : 'non-checked-label w-100'}
                    key={ingre}
                  >
                    <input
                      type="checkbox"
                      id={ingre}
                      name={ingre}
                      // checked={Items.includes(ingre)}
                      onChange={handleCheckboxChange}
                      className="form-check-input"
                    />
                    {capitalizeFirstLetter(ingre)}
                  </label>
                ))}
            </div>
          </Scrollbars>
          <button className="remove-button-result mb-2" onClick={handleDeleteItems} type="button">
            Malzeme Sil
          </button>
        </div>
      </Col>
    </Row>
  );
}

export default ItemsChosen;
