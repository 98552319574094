import './ButtonCTA.css';

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ButtonCTA(props) {
  return (
    <Link to={props.redirect} onClick={props.onClick} className={props.linkClassName} style={{ textDecoration: 'none' }}>
      <Button className={`${props.className || 'green'} CTA`} style={props.style}>
        {props.text}
      </Button>
    </Link>
  );
}

export default ButtonCTA;
