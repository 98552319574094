import { ITEMS_START, ITEMS_SUCCESS, ITEMS_FAIL } from "../actions/actionTypes";
import { updateObject } from "../utility";


const initialState = {
  items: [],
  error: null,
  loading: false
};

const itemsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const itemsSuccess = (state, action) => {
  return updateObject(state, {
    items: action.data,
    error: null,
    loading: false
  });
};

const itemsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_START:
      return itemsStart(state, action);
    case ITEMS_SUCCESS:
      return itemsSuccess(state, action);
    case ITEMS_FAIL:
      return itemsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
