import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class CookiePolicy extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Çerez Politikası | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Çerez Politikası | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Çerez Politikası | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Çerez Politikası | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Çerez Politikası
                                    </h1>
                                    <p class="mt-3 mb-5 static-content">
                                        Son güncellenme: 11/04/2023<br/><br/>
                                        Biz, Wasteys, olarak güvenliğinize önem veriyor ve bu Çerez Politikası ile siz sevgili ziyaretçilerimizi,
                                        web sitemizde hangi çerezleri, hangi amaçla kullandığımız ve çerez ayarlarınızı nasıl değiştireceğiniz
                                        konularında kısaca bilgilendirmeyi hedefliyoruz.<br/>
                                        Sizlere daha iyi hizmet verebilmek adına, çerezler vasıtasıyla, ne tür kişisel verilerinizin hangi amaçla
                                        toplandığı ve nasıl işlendiği konularında, kısaca bilgi sahibi olmak için lütfen bu Çerez Politikasını
                                        okuyunuz. Daha fazla bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle çekinmeden iletişime
                                        geçebilirsiniz.<br/><br/>
                                        Çerez Nedir?<br/><br/>
                                        Çerezler, kullanıcıların web sitelerini daha verimli bir şekilde kullanabilmeleri adına, cihazlarına
                                        kaydedilen küçük dosyacıklardır. Çerezler vasıtasıyla kullanıcıların bilgilerinin işleniyor olması
                                        sebebiyle, 6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince, kullanıcıların bilgilendirilmeleri
                                        ve onaylarının alınması gerekmektedir.<br/>
                                        Bizler de siz sevgili ziyaretçilerimizin, web sitemizden en verimli şekilde yararlanabilmelerini ve siz
                                        sevgili ziyaretçilerimizin kullanıcı deneyimlerinin geliştirilebilmesini sağlamak adına, çeşitli çerezler
                                        kullanmaktayız.<br/><br/>
                                        1. Zorunlu Çerezler<br/><br/>
                                        Zorunlu çerezler, web sitesine ilişkin temel işlevleri etkinleştirerek web sitesinin kullanılabilir hale
                                        gelmesini sağlayan çerezlerdir. Web sitesi bu çerezler olmadan düzgün çalışmaz.<br/><br/>
                                        2. Performans Çerezleri<br/><br/>
                                        Performans çerezleri, ziyaretçilerin web sitesine ilişkin kullanım bilgilerini ve tercihlerini anonim olarak
                                        toplayan ve bu sayede web sitesinin performansının geliştirilmesine olanak sağlayan çerezlerdir.<br/><br/>
                                        3. Fonksiyonel Çerezler<br/><br/>
                                        Fonksiyonel çerezler, kullanıcıların web sitesine ilişkin geçmiş kullanımlarından yola çıkılarak
                                        gelecekteki ziyaretlerinde tanınmalarını ve hatırlanmalarını sağlayan ve bu sayede web sitelerinin
                                        kullanıcılara dil, bölge vb. gibi kişiselleştirilmiş bir hizmet sunmasına olanak tanıyan çerezlerdir.
                                        4. Reklam Çerezleri<br/><br/>
                                        Reklam çerezleri, üçüncü taraflara ait çerezlerdir ve web sitelerinde ziyaretçilerin davranışlarını
                                        izlemek için kullanılırlar. Bu çerezlerin amaçları, ziyaretçilerin ihtiyaçlarına yönelik ilgilerini çekecek
                                        reklamların gösterilmesine yardımcı olmaktır ve sorumluluğu çerez sahibi üçüncü taraflara aittir.<br/><br/>
                                        Çerezler İle İşlenen Kişisel Veriler Nelerdir?<br/><br/>
                                        Kimlik (isim, soy isim, doğum tarihi vb.) ve iletişim (adres, e-posta adresi, telefon, IP, konum vb.)
                                        bilgileriniz tarafımızca, çerezler (cookies) vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle
                                        ve bazen de analitik sağlayıcılar, reklam ağları, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi
                                        üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve
                                        sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat işleme şartına dayanılarak işlenecektir.<br/><br/>
                                        Çerezler Hangi Amaçla Kullanılmaktadır?<br/><br/>
                                        Web sitemizde, şüpheli eylemlerin tespiti yoluyla güvenliğin sağlanması, kullanıcıların tercihleri
                                        doğrultusunda işlevsellik ve performansın artırılması, ürünlerin ve hizmetlerin geliştirilmesi ve
                                        kişiselleştirilmesi ile bu hizmetlere ulaşımın kolaylaştırılması, sözleşmesel ve hukuki sorumlulukların
                                        yerine getirilmesi amaçlı çerezler kullanmaktadır. Ayrıca kullanıcıların daha geniş kapsamlı hizmet
                                        sağlayıcılar ile buluşturulabilmesi amacıyla reklam çerezleri ve üçüncü taraflarla bilgi paylaşımı da söz
                                        konusudur.<br/><br/>
                                        Çerezler Nasıl Yönetilmektedir?<br/><br/>
                                        Tüm bu açıklamalardan sonra, hangi çerezlerin kullanılacağı konusu, tamamen kullanıcılarımızın
                                        özgür iradelerine bırakılmıştır. Çerez tercihlerinizi, tarayıcınızın ayarlarından silerek ya da
                                        engelleyerek, web sitemize adım attığınız anda yönetebilir ya da gelecekte, istediğiniz zaman bu
                                        ayarları değiştirebilirsiniz. Daha ayrıntılı bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle
                                        info@wasteys.com e-mail adresi üzerinden iletişime geçebilirsiniz.<br/><br/>
                                        Web Sitesi'nde Yer Alan Çerezler<br/><br/>

                                        Çerezin Adı: Google Analytics<br/>
                                        Kullanım Amacı: Google Analytics, çerezleri ziyaretçilerin Web Sitesini nasıl kullandıklarını analiz etmek amacıyla kullanır. Bu Web Sitesini kullanımınızla ilgili bilgiler (IP adresiniz dahil) Google'a aktarılarak Google tarafından ABD'deki sunucularda saklanmaktadır. Google bu bilgileri Web Sitesini kullanımınızı değerlendirmek, Şirket için Web Sitesi faaliyetini derlemek ve Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi Google tarafından depolanan diğer verilerle eşleştirmeyecektir. Google Analytics kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: http://www.google.co.uk/intl/ en/analytics/privacyoverview.html
                                        <br/>Kullanım Süresi: Analitik Çerezler / Oturum Çerezleri<br/><br/>

                                        Çerezin Adı: Google Ads
                                        <br/>Kullanım Amacı: Google Ads, çerezleri, güvenli arama tercihlerinizi hatırlamak, size daha alakalı reklamlar göstermek, bir sayfaya kaç ziyaretçi geldiğini belirlemek, hizmetlerimize kaydolmanıza yardımcı olmak, verilerinizi korumak veya reklam ayarlarınızı hatırlamak için kullanır. Bu, web sitesinin ziyaretinizle ilgili bilgileri (örneğin, tercih ettiğiniz dil ve diğer ayarlar) hatırlamasına yardımcı olur. Bu Web Sitesini kullanımınızla ilgili bilgiler (IP adresiniz dahil) Google'a aktarılarak Google tarafından ABD'deki sunucularda saklanmaktadır. Google bu bilgileri Web Sitesini kullanımınızı değerlendirmek, Şirket için Web Sitesi faaliyetini derlemek ve Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi Google tarafından depolanan diğer verilerle eşleştirmeyecektir. Google Ads çerez kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: https://policies.google.com/privacy?hl=tr
                                        Kullanım Süresi: Analitik Çerezler / Oturum Çerezleri<br/><br/>

                                        Çerezleri Engelleme<br/><br/>
                                        Tarayıcınızın ayarlarını değiştirerek çerezleri engelleyebilirsiniz. Ancak, çerezleri engellemeniz durumunda, web sitemizin tüm özelliklerini kullanamayabilirsiniz. Çerezleri engellemek için tarayıcınızın ayarlarında çerezleri devre dışı bırakabilirsiniz. Ayrıca, herhangi bir çerezin yüklendiği durumda uyarılar alabilirsiniz. Ancak, çerezleri engellemeniz durumunda, bazı web sitelerinin doğru şekilde çalışmayabileceğini veya bazı özelliklerin kullanılamayacağını unutmayın. Çerezleri nasıl devre dışı bırakabileceğinizle ilgili daha fazla bilgi için tarayıcınızın yardım dosyalarına veya üreticisinin web sitesine başvurabilirsiniz.
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default CookiePolicy;
