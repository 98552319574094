import '../assets/css/Homepage.css';

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import og from '../assets/img/og.jpg';
import tomato from '../assets/img/tomato-min.png';
import ButtonCTA from '../components/buttonCTA/ButtonCTA';
import GrayBanner from '../components/GrayBanner';
import WhiteBanner from '../components/WhiteBanner';
import GoogleAd from '../components/GoogleAd';

function Homepage() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [scroll, setScroll] = useState(true);

  window.onscroll = () => {
    if (
      document.documentElement.scrollHeight - document.documentElement.scrollTop <
        document.documentElement.clientHeight + 1900 &&
      scroll &&
      localStorage.getItem('cookieSeen') !== 'shown' &&
      document.documentElement.scrollHeight > 2000
    ) {
      setPopupVisible(true);
      setScroll(false);
      localStorage.setItem('cookieSeen', 'shown');
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };
  return (
    <div>
      <Helmet>
        <title>Wasteys | Malzemeye Göre Yemek</title>
        <meta
          name="description"
          content="Malzemeye göre yemek tarifi bulun. Elinizdeki malzemeleri seçin ve  17 bin tarif arasından yapabileceğiniz yemekleri görün. Bugün ne pişireceğim diyorsanız, evinizdeki malzemeleri seçin, 17 bin tarif arasından yapabileceğiniz yemek tariflerini görüntüleyin."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Wasteys'e Hoşgeldiniz" />
        <meta name="twitter:description" content="Elinizdeki malzemelerle yapabileceğiniz yemekleri görün." />
        <meta name="twitter:image" content={og} />

        <meta property="og:title" content="Wasteys'e Hoşgeldiniz" />
        <meta property="og:description" content="Elinizdeki malzemelerle yapabileceğiniz yemekleri görün." />
        <meta property="og:image" content={og} />
        <meta property="og:url" content="https://wasteys.com/" />
        <meta property="og:site_name" content="Wasteys'e Hoşgeldiniz" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="article" />
      </Helmet>
      {popupVisible && (
        <div className="cookie-banner">
          <div className="container d-flex align-items-center justify-content-center">
            <p className="banner-p my-auto pr-3">
              Bu siteyi kullanarak &quot;
              <a href="/kvk" className="kvkk-tag-banner">
                Kişisel Verilerin Korunması
              </a>
              &quot; başlığı altındaki metni ve &quot;
              <a href="/cerez-politikasi" className="kvkk-tag-banner">
                Gizlilik ve Çerez Politikamızı
              </a>
              &quot; kabul etmiş olmaktasınız.
            </p>
            <button className="close-tag" style={{ cursor: 'pointer' }} onClick={handlePopupClose} type="button">
              &#10006;
            </button>
          </div>
        </div>
      )}
      <Container>
        <Row className="homepage-container">
          <Col className="v-center pt-4">
            <div>
              <h1>
                Malzemelere Göre <br /> Tarif
              </h1>
              <p>
                Evinizdeki malzemeler ile 17 bin tarif* arasından yapabileceğiniz tarifleri görün. Üye olun, kendi
                dolabınızı oluşturun, beğendiğiniz tarifleri favorilerinize ekleyin.
              </p>
              <ButtonCTA text="Hemen Seç" redirect="/search" />
            </div>
          </Col>
          <Col className="v-center justify-content-end">
            <img src={tomato} alt="Tomato" />
          </Col>
        </Row>
        <Row className="pb-5">
          <Col className="text-center">
            <div>
              <h2>Her malzemenizi kullanacaksınız!</h2>
              <p>
                Evinize giren herhangi bir gıda malzemesinin çöpe atılmasını sizin gibi biz de istemiyoruz. <br />
                Bunun için 400 malzeme arasından, evinizdeki malzemeleri seçip daha önce denemediğiniz lezettleri
                tadabilir ve dünyaya bir katkınız olabilir.
              </p>
            </div>
          </Col>
        </Row>
        <div style={{ height: '50px' }} />
        <GrayBanner
          title="Malzemelerini Seç"
          text="Sahip olduğun malzemeleri seç, 17 bin tarif arasından yapabileceğin yemekleri gör ve sevdiğin yemekleri favorilerine ekle."
          redirect="/favorite"
          page="Favorilerim"
          source="choose"
        />
        <GrayBanner
          title="Dolabına Ekle"
          text="Sana özel oluşturulacak dolabına malzemelerini gir, yapabileceğin yüzlerce yemeği gör. İstediğin zaman malzemeleri ekle veya çıkar."
          redirect="/fridge"
          page="Dolabım"
          source="fridge"
        />
        </Container>
        <div className='homepage-ad-div mt-5'>
          <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="6327392518"/>    
          <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="2229732383"/>    
        </div>
        <Container>   
        {/* <div style={{ height: '150px', width: '100%' }}>
          <GoogleAd className="wasteys-homepage-horizontal" dataAdSlot="2229732383"/>    
        </div> */}
        <Row className="bg-light my-5 mx-3 rounded-lg">
          <Col className="hv-center py-5 text-center">
            <div>
              <h2>Daha az para, daha çok sağlık</h2>
              <p>
                Evinizdeki hiçbir gıda malzemesini atmayacaksınız. Daha da güzeli bu malzemeler ile çok sağlıklı ve
                çeşitli yemekler yapacaksınız. Hemen deneyin!
              </p>
            </div>
          </Col>
        </Row>
        <WhiteBanner
          title="Dolapta unutmayın"
          text="Aldığınızı unutup, daha sonra çöpe atmak zorunda olduğunuz hiçbir malzeme olmayacak, her malzemeyi kullanabileceğiniz 17 bin tarifimiz var."
          redirect="/fridge"
          page="Dolabım"
          source="tomatos"
        />
        <WhiteBanner
          title="Farklı lezzetler deneyin"
          text="Her hafta aynı yemekleri yapmak yerine her zaman kullandığınız malzemeleri kullanarak bambaşka yemekler yapacaksınız."
          redirect="/favorite"
          page="Favorilerim"
          source="soup"
        />
        <WhiteBanner
          title="Paranız cebinizde kalsın"
          text="Dolabınızdaki her malzemeye uygun tariflerimizle, hiçbir malzemeyi dolayısıyla paranızı çöpe atmaycaksınız."
          redirect="/search"
          page="Malzeme Seç"
          source="money"
        />
      </Container>
        <div className='homepage-ad-div mb-5'>
          <GoogleAd className="wasteys-hompage-ad-mobile" dataAdSlot="1529241847"/>    
          <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="4187178862"/>    
        </div>
      <Container>
        {/* <div style={{ height: '150px', width: '100%' }}>
          <GoogleAd className="wasteys-homepage-horizontal" dataAdSlot="4187178862"/>    
        </div> */}
        {/* <Row className="pt-5 w-100 mx-auto" style={{ background: '#ffee84' }}> */}
        <Row className="pt-5" style={{ background: '#ffee84' }}>
          <Col className="hv-center">
            <div className="w-100 text-center">
              <h2>Hadi</h2>
              <p>
                Uzun zamandır bunu beklediğinizi biliyoruz. <br /> Malzemelerini seç, tarifleri bul.
              </p>
              <ButtonCTA text="Hemen Seç" redirect="/search" />
              <p className="pt-4" style={{ fontSize: '11px' }}>
                *Tarifler yemek.com tarifleridir.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Homepage;
