import React from "react";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import GreenCTA from "../../components/greenCTA/GreenCTA";
import og_img from '../../assets/img/og.jpg';
import { Helmet } from 'react-helmet-async';


class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className="login-outer-div d-flex align-items-center">
                <Helmet>‍
                    <title>Gizlilik Politikası | Wasteys</title>‍
                    <meta name="description" content="Sıfır atık. Wasteys, evdeki malzemeleri seçerek yapabileceğiniz yemek tariflerini gösteren bir web sitesidir." />  

                    <meta name="twitter:card" content="summary_large_image" />        
                    <meta name="twitter:site" content="@user" />        
                    <meta name="twitter:creator" content="@user" />        
                    <meta name="twitter:title" content="Gizlilik Politikası | Wasteys" />        
                    <meta name="twitter:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta name="twitter:image" content={ og_img }/>        

                    <meta property="og:title" content="Gizlilik Politikası | Wasteys" />        
                    <meta property="og:description" content="Elinizdeki malzemeleri neden çöpe atmamalısınız?" />        
                    <meta property="og:image" content={ og_img }/>
                    <meta property="og:url" content="https://wasteys.com/why/" />
                    <meta property="og:site_name" content="Gizlilik Politikası | Wasteys" />
                    <meta property="og:locale" content="tr_TR" />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container fluid className="px-0">
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h1 className="title text-center" style={{ fontSize: "2rem", letterSpacing:"0.08rem"}}>
                                    Gizlilik Politikası
                                    </h1>
                                    <p class="mt-3 mb-5 static-content">
                                        <strong>Gizlilik Politikası</strong>
                                        <br/> wasteys.com olarak kişisel gizlilik haklarınıza saygı duyuyor ve sitemizde geçirdiğiniz süre zarfında bunu sağlamak için çaba sarfediyoruz. Kişisel bilgilerinizin güvenliği ile ilgili tanımlar aşağıda açıklanmış ve bilginize sunulmuştur. Sözleşme hakkında sorularınız olursa lütfen info@wasteys.com adresine mail atmaktan çekinmeyiniz. wasteys.com olarak ziyaretçilerimizin gizliliği konusu bizim önceliklerimizden biridir.wasteys.com, Site dahilinde başka sitelere link verebilir. wasteys.com, link vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. <br/>
                                        <br/>
                                        <strong>Kişisel Bilgileriniz</strong>
                                        <br/> Birçok standart web sunucusunda olduğu gibi wasteys.com da istatistiksel amaçlı log dosyaları kaydı tutmaktadır. Bu dosyalar; ip adresiniz, internet servis sağlayıcınız, tarayıcınızın özellikleri, işletim sisteminiz ve siteye giriş, çıkış sayfalarınız gibi standart bilgileri içermektedir. Log dosyaları kesinlikle istatistiksel amaçlar dışında kullanılmamakta ve mahremiyetinizi ihlal etmemektedir. Ip adresiniz ve diğer bilgiler, şahsi bilgileriniz ile ilişkilendirilmemektedir. <br/>
                                        <br/>Site üzerindeki çeşitli formların doldurulması suretiyle kullanıcıların kendileriyle ilgili bilgileri wasteys.com'a vermeleri gerekmektedir. wasteys.com tarafından talep edilen bilgiler ve/veya kullanıcı tarafından sağlanan bilgiler ve/veya Site üzerinden yapılan işlemlerle ilgili bilgiler; wasteys.com ve işbirliği içinde olduğu kişiler tarafından kullanıcının kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veritabanı oluşturma ve pazar araştırmalarında kullanılabilir. <br/>
                                        <br/>wasteys.com, kişisel bilgileri kesinlikle özel ve gizli tutmayı; bunu bir sır saklama yükümlülüğü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir. wasteys.com’un gerekli bilgi güvenliği önlemlerini almasına karşın, Site’ye ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, wasteys.com’unn herhangi bir sorumluluğu olmayacaktır. <br/>
                                        <br/>
                                        <strong>Çerezler</strong>
                                        <br/> “Cookie, çerez” kelimesi web sayfası sunucusunun sizin bilgisayarınızın hard diskine yerleştirdiği ufak bir text dosyasını tanımlamak için kullanılmaktadır. Sitemizin bazı bölümlerinde kullanıcı kolaylığı sağlamak için çerez kullanılıyor olabilir. Ayrıca sitede mevcut bulunan reklamlar aracılığıyla, reklam verilerinin toplanması için cookie ve web beacon kullanılıyor olabilir. Bazı reklamveren ortakları çerezlerini ve Web işaretleyicilerini kullanabilir. Bu reklamveren ortakları arasında Google Adsense de vardır. Bu bize bağlı olmayan sunucular ve reklam ağları kendi teknolojilerini wasteys.com`'`u kullanarak tarayıcınıza bilgileri gönderebilir. Bu tamamen sizin izninizle gerçekleşiyor olup, isteğiniz dahilinde internet tarayıcınızın ayarlarını değiştirerek bunu engellemeniz mümkündür. wasteys.com reklam şirketlerinin çerezleri üzerinde herhangi bir kontrolü yoktur Bu konuda daha detaylı bilgi için reklamveren ortaklarımıza başvurabilirsiniz yada yukarıda belirtmiş olduğumuz mail adresinden iletişime geçerek, reklamveren ortaklarımıza ulaşma anlamında yardım isteyebilirsiniz. wasteys.com bildiriminde anlatıldığı gibi reklamveren ortaklarımızın uygulamaları üzerinde bir kontrolümüz yoktur. Eğer çerezlerinizi iptal etmek istiyorsanız tarayıcı seçeneklerinizden ayarlayabilirsiniz. Daha detaylı bilgi için tarayıcınızın yardım dosyalarını inceleyiniz. <br/>
                                        <br/>
                                        <strong>Çocukların Gizliliği</strong>
                                        <br/> wasteys.com 18 yaşın altındaki kimseye hitap etmemektedir. 18 yaşın altındaki çocuklardan bilerek kişisel olarak tanımlanabilir bilgi toplamıyoruz. 18 yaşın altındaki bir çocuğun bize kişisel bilgi sağladığını tespit ettiğimizde, bunu derhal sunucularımızdan siliyoruz. Ebeveyn iseniz ve çocuğunuzun bize kişisel bilgiler sağladığını düşünüyorsanız, gerekli işlemleri yapabilmemiz için lütfen bizimle iletişime geçin . <br/>
                                        <br/>
                                        <strong>Dış Bağlantılar</strong>
                                        <br/>wasteys.com sitesi, internetin doğası gereği birçok farklı internet adresine bağlantı vermektedir. wasteys.com link verdiği, banner tanıtımını yaptığı sitelerin içeriklerinden veya gizlilik prensiplerinden sorumlu değildir. Burada bahsedilen bağlantı verme işlemi, hukuki olarak “atıfta bulunma” olarak değerlendirilmektedir. <br/>
                                        <br/>
                                        <strong>Gizlilik Politikasında yapılabilecek değişiklikler</strong>
                                        <br/> wasteys.com olarak bu sayfada yer alan gizlilik politikasını değiştirebilir veya yeni maddeler ekleyebiliriz. Bu nedenle bu sayfayı düzenli olarak incelemenizi tavsiye ederiz. <br/>
                                        <br/>
                                        <strong>İletişim</strong>
                                        <br/> Gizlilik politikamızda yer alan maddeler ile ilgili soru veya önerileriniz var ise lütfen bizimle iletişime geçmekten çekinmeyin. Bize info@wasteys.com adresi üzerinde ulaşabilirsiniz.
                                    </p>
	                                <GreenCTA text="Tarif Bul" redirect="/search" />
                                </div>                        
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}export default PrivacyPolicy;
