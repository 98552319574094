// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./why_veggie.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.why-row {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-position: center;   \n}", "",{"version":3,"sources":["webpack://src/containers/why/why.css"],"names":[],"mappings":";AACA;IACI,yDAAuC;IACvC,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":["\n.why-row {\n    background-image: url(\"why_veggie.jpg\");\n    background-size: cover;\n    background-position: center;   \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
