/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import '../../assets/css/ConfirmResetPassword.css';

import React from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import slash from '../../assets/img/eye-slash-solid.svg';
import eye from '../../assets/img/eye-solid.svg';
import og from '../../assets/img/og.jpg';
import AuthGreen from '../../components/authenticationGreenCol/AuthGreen';
import { passwordResetConfirm, passwordResetConfirmRestart } from '../../store/actions/auth';

class ConfirmResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
    };

    this.handleTogglePassword = this.handleTogglePassword.bind(this);
    this.handleTogglePassword2 = this.handleTogglePassword2.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.passwordResetConfirmRestart();
  }

  handleReset(e) {
    e.preventDefault();
    const { password1, password2 } = this.state;
    this.props.passwordResetConfirm(this.props.match.params.uid, this.props.match.params.token, password1, password2);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleTogglePassword() {
    const { showPassword1 } = this.state;
    this.setState({ showPassword1: !showPassword1 });
  }

  handleTogglePassword2() {
    const { showPassword2 } = this.state;
    this.setState({ showPassword2: !showPassword2 });
  }

  render() {
    const { showPassword1, showPassword2 } = this.state;
    const { token, error, loading, resetConfirmation } = this.props;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <div className="login-outer-div d-flex align-items-center">
        <Helmet>
          ‍<title>Şifre Sıfırla | Wasteys</title>‍
          <meta
            name="description"
            content="Elinizdeki malzemeleri seçin ve  17 bin tarif arasından yapabileceğiniz yemekleri görün. Seçtiğiniz malzemeleri dolabınıza ekleyin, dilediğiniz zaman ekleyip çıkartın. Beğendiğiniz tarifleri favorilerinize ekleyin."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />
          <meta name="twitter:title" content="Şifre Sıfırla | Wasteys" />
          <meta name="twitter:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta name="twitter:image" content={og} />
          <meta property="og:title" content="Şifre Sıfırla | Wasteys" />
          <meta property="og:description" content="Giriş yaparak kendi dolabınızı oluşturabilirsiniz." />
          <meta property="og:image" content={og} />
          <meta property="og:site_name" content="Şifre Sıfırla | Wasteys" />
          <meta property="og:locale" content="tr_TR" />
          <meta property="og:type" content="article" />
        </Helmet>

        <Container className="my-5">
          <Row className="mx-auto login-row ">
            <Col className="p-0" md={5}>
              <AuthGreen
                className="p-3"
                title="Atıksız Bir Dünya Düşünün"
                text="Türkiye’de her yıl ürettiğimiz gıdanın 3’te 1’ini, 25 milyon ton gıdayı, çöpe atıyoruz..."
                redirect="/fridge"
                page="Dolabım"
                source="tomatos"
              />
            </Col>
            <Col className="py-md-5 py-4" md={7}>
              <Container className="">
                <Row className="mx-auto">
                  <Col>
                    <h5 className="mt-1 mb-3">Şifrenizi Sıfırlayın</h5>
                    {error && [
                      Array.isArray(error) ? (
                        error.map(error => {
                          if (error[0] === 'E-mail is not verified.') {
                            return (
                              <p key="1" className="auth-form-p mb-3">
                                Lütfen e-postanızı onaylayın.
                              </p>
                            );
                          }
                          if (error[0] === 'Incorrect input. access_token or code is required.') {
                            return (
                              <p key="2" className="auth-form-p mb-3">
                                Geçersiz giriş, lütfen tekrar deneyiniz.
                              </p>
                            );
                          }
                          return (
                            <p key={3} className="auth-form-p mb-3">
                              Lütfen tekrar deneyiniz.
                            </p>
                          );
                        })
                      ) : (
                        <p className="auth-form-p mb-3"> Lütfen daha sonra tekrar deneyiniz. </p>
                      ),
                    ]}
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="mx-auto">
                  <Col>
                    <Form onSubmit={this.handleReset}>
                      <Form.Group controlId="formBasicPassword1" style={{ position: 'relative' }}>
                        <Form.Control
                          type={showPassword1 ? 'text' : 'password'}
                          placeholder="Yeni Şifreniz"
                          required
                          onChange={this.handleChange}
                          name="password1"
                        />
                        <img
                          className="eye-icon"
                          src={showPassword1 ? slash : eye}
                          alt="Logo"
                          onClick={this.handleTogglePassword}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword2" style={{ position: 'relative' }}>
                        <Form.Control
                          type={showPassword2 ? 'text' : 'password'}
                          placeholder="Yeni Şifreniz Tekrar"
                          required
                          onChange={this.handleChange}
                          name="password2"
                        />
                        <img
                          className="eye-icon"
                          src={showPassword2 ? slash : eye}
                          alt="Logo"
                          onClick={this.handleTogglePassword2}
                        />
                      </Form.Group>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-4">
                          <Spinner animation="border" role="status" style={{ color: '#319DA4' }}>
                            <span className="sr-only ">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <Button className="auth-button mt-3" variant="primary" type="submit">
                          Şifre Sıfırla
                        </Button>
                      )}
                      {resetConfirmation && (
                        <p className="reset-form-p mt-3 mb-0">
                          Şifreniz başarıyla sıfırlandı, yeni kullanıcı bilgilerinizle giriş yapabilirsiniz.
                        </p>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  loading: state.auth.loading,
  error: state.auth.error,
  resetConfirmation: state.auth.resetConfirmation,
});

const mapDispatchToProps = dispatch => ({
  passwordResetConfirm: (uid, token, password1, password2) =>
    dispatch(passwordResetConfirm(uid, token, password1, password2)),
  passwordResetConfirmRestart: () => dispatch(passwordResetConfirmRestart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmResetPassword);
