export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CONFIRMATION = "AUTH_CONFIRMATION";
export const AUTH_CONFIRMATION_LOGIN = "AUTH_CONFIRMATION_LOGIN";
export const AUTH_RESET_START = "AUTH_RESET_START";

export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_RESTART = "PASSWORD_RESET_RESTART";
export const PASSWORD_RESET_CONFIRM_START = "PASSWORD_RESET_CONFIRM_START";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_RESTART = "PASSWORD_RESET_CONFIRM_RESTART";

export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const GET_ERRORS = "GET_ERRORS";

export const ITEMS_START = "ITEMS_START";
export const ITEMS_SUCCESS = "ITEMS_SUCCESS";
export const ITEMS_FAIL = "ITEMS_FAIL";

export const ITEMS_CHOSEN_START = "ITEMS_CHOSEN_START";
export const ITEMS_CHOSEN_SUCCESS = "ITEMS_CHOSEN_SUCCESS";
export const ITEMS_CHOSEN_FAIL = "ITEMS_CHOSEN_FAIL";

export const ITEMS_FRIDGE_START = "ITEMS_FRIDGE_START";
export const ITEMS_FRIDGE_SUCCESS = "ITEMS_FRIDGE_SUCCESS";
export const ITEMS_FRIDGE_FAIL = "ITEMS_FRIDGE_FAIL";
export const ITEMS_FRIDGE_RESET = "ITEMS_FRIDGE_RESET";

export const FAVORITE_START = "FAVORITE_START";
export const FAVORITE_SUCCESS = "FAVORITE_SUCCESS";
export const FAVORITE_FAIL = "FAVORITE_FAIL";
export const FAVORITE_RESET = "FAVORITE_RESET";

export const RECIPE_START = "RECIPE_START";
export const RECIPE_SUCCESS = "RECIPE_SUCCESS";
export const RECIPE_FAIL = "RECIPE_FAIL";
export const RECIPE_RESET = "RECIPE_RESET";

export const RECIPE_CATEGORY_START = "RECIPE_CATEGORY_START";
export const RECIPE_CATEGORY_SUCCESS = "RECIPE_CATEGORY_SUCCESS";
export const RECIPE_CATEGORY_FAIL = "RECIPE_CATEGORY_FAIL";

