import './noMatch/nomatchpage.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ButtonCTA from '../components/buttonCTA/ButtonCTA';

function Error() {
  return (
    <Container
      className=" mt-4 d-flex align-items-center justify-content-center text-center"
      style={{ minHeight: '40vh' }}
    >
      <div>
        <Row>
          <Col className="px-4">
            <h3>Üzgünüz, bir sorunla karşılaştık :(</h3>
            <p>
              Aradığınız sayfayı bulamadık ama bizden vazgeçmenizi istemiyoruz. Aşağıdaki butona tıklayarak, malzeme
              seçebilir ve anında 17 bin tarif arasından yapabileceğiniz tarifleri görebilirsiniz.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center text-center search-page-button">
            <ButtonCTA redirect="/search" className="green" text="Malzeme Seç" />
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Error;
